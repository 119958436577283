import projectThumbnailSrc from '@assets/images/project-thumbnail.png';
import {
  cn,
  Image,
  ImageProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RiInformationFill,
} from '@landler/tw-component-library';
import StaticMap, { StaticMapRequest } from '@mapbox/mapbox-sdk/services/static';
import bbox from '@turf/bbox';
import { feature, featureCollection } from '@turf/helpers';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DraftPlot, Plot, PlotStatusEnum, PlotType } from '@/api/rest/resources/types/plot';
import { MAPBOX_NO_LABELS } from '@/config/constants';
import { rgbaToHex } from '@/utils/colors';
import { getColorOfPlotType } from '@/utils/plot';

const staticClient = StaticMap({
  accessToken: MAPBOX_NO_LABELS.token,
});

export type ProjectThumbnailProps = Omit<ImageProps, 'src'> & { plots: Pick<Plot, 'polygon' | 'type'>[] };

export const ProjectThumbnail: FC<ProjectThumbnailProps> = ({ plots, className, ...delegated }) => {
  const plotsBoundingBox = useMemo(() => {
    const collection = featureCollection(plots.map((plot) => feature(plot.polygon)));
    return bbox(collection);
  }, [plots]);

  const staticMap = useMemo(() => {
    const staticMapRequest = staticClient.getStaticImage({
      ...defaultMapStyles,
      position: {
        // @ts-ignore library typings seem to be incorrect
        bbox: plotsBoundingBox,
      },
    });

    return staticMapRequest.url();
  }, [plotsBoundingBox]);

  return (
    <div className={cn('relative flex-shrink-0', className)}>
      <Image
        alt='project-thumbnail'
        src={staticMap}
        fallback={projectThumbnailSrc}
        className={cn('bg-secondary-30', className)}
        {...delegated}
      />
      <div className='absolute bottom-0 right-1'>
        <Attribution />
      </div>
    </div>
  );
};

export type PlotThumbnailProps = Omit<ImageProps, 'src'> & {
  plot: Pick<Plot, 'polygon' | 'type'> | Pick<DraftPlot, 'polygon' | 'land_type'>;
};

export const PlotThumbnail: FC<PlotThumbnailProps> = ({ plot, className, ...delegated }) => {
  const staticMap = useMemo(() => {
    const polygonColor = getColorOfPlotType((plot as Plot).type || (plot as DraftPlot).land_type || PlotType.UNDEFINED);
    const polygonColorHex = rgbaToHex(polygonColor);

    const isAnalysed = (`status` in plot && plot.status === PlotStatusEnum.analysed) ?? false;
    const staticMapRequest = staticClient.getStaticImage({
      ...defaultMapStyles,
      overlays: [
        {
          geoJson: {
            type: 'Feature',
            geometry: plot.polygon,
            properties: {
              fill: polygonColorHex,
              stroke: polygonColorHex,
              'fill-opacity': isAnalysed ? 1 : 0.4,
            },
          },
        },
      ],
    });

    return staticMapRequest.url();
  }, [plot]);

  return (
    <div className={cn('relative flex-shrink-0', className)}>
      <Image
        alt='plot-thumbnail'
        src={staticMap}
        fallback={projectThumbnailSrc}
        className={cn('bg-secondary-30', className)}
        {...delegated}
      />
      <div className='absolute bottom-0 right-1'>
        <Attribution />
      </div>
    </div>
  );
};

const Attribution = () => {
  const { t } = useTranslation();

  return (
    <Popover>
      <PopoverTrigger onClick={(e) => e.stopPropagation()} data-testid='attribution-button'>
        <RiInformationFill className='fill-white-100/80' />
      </PopoverTrigger>

      <PopoverContent size='small' className='typography-caption' data-testid='attribution-content'>
        <span>
          ©{' '}
          <a
            href='https://www.mapbox.com/about/maps/'
            className='hover:underline active:underline'
            target='_blank'
            rel='noreferrer'
          >
            Mapbox
          </a>
        </span>
        <span>
          ©{' '}
          <a
            href='http://www.openstreetmap.org/copyright'
            className='hover:underline active:underline'
            target='_blank'
            rel='noreferrer'
          >
            OpenStreetMap
          </a>
        </span>
        <a
          href='https://www.mapbox.com/map-feedback/'
          className='hover:underline active:underline'
          target='_blank'
          rel='noreferrer'
        >
          {t('global.misc.improveThisMap')}
        </a>
        <a href='https://maxar.com' className='hover:underline active:underline' target='_blank' rel='noreferrer'>
          Maxar
        </a>
      </PopoverContent>
    </Popover>
  );
};

const defaultMapStyles: StaticMapRequest = {
  height: 128,
  width: 128,
  ownerId: MAPBOX_NO_LABELS.ownerId,
  styleId: MAPBOX_NO_LABELS.styleId,
  highRes: true,
  position: 'auto',
  attribution: false,
  logo: false,
  padding: '16',
};
