import { cn, Container, Stack, toast } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, Navigate, Outlet, useLocation } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useMembershipType } from '@/hooks/useMembershipType';
import { usePathSet } from '@/hooks/usePathSet';
import { SignedInPageLayout } from '@/layout/signed-in-page';
import { Notices } from '@/pages/shared/components/Notices';
import { useMenuItems } from '@/pages/shared/hooks/useMenuItems';
import { usePlotAggregate } from '@/pages/shared/hooks/usePlotAggregate';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';

import { NoPlotsHero } from './components/NoPlotsHero';
import { ProjectMap } from './components/ProjectMap';
import { ProjectOverview } from './components/ProjectOverview';
import { ProjectSecondaryNav } from './components/ProjectSecondaryNav';

export const SustainableProductionProjectPage = () => {
  const { t } = useTranslation();

  const menuItems = useMenuItems();
  const projectId = useProjectId();
  const pathSet = usePathSet();

  const membershipType = useMembershipType();
  const plots = usePlotsForProject().data.results;

  const { plots_requiring_attention: invalidPlotsCount } = usePlotAggregate().data;

  if (plots.length === 0) {
    if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) {
      return (
        <SignedInPageLayout items={menuItems} withCustomContentContainer>
          <ProjectSecondaryNav />
          <NoPlotsHero />
        </SignedInPageLayout>
      );
    }

    toast({
      title: t('shared.projects.project.invalidProjectToast'),
      type: 'error',
    });

    return <Navigate to={pathSet.projects} replace />;
  }

  return (
    <SignedInPageLayout items={menuItems} withCustomContentContainer>
      <ProjectSecondaryNav />
      <Container gutterWidth={{ sm: 4 }} className='my-10' data-testid='sustainable-production-project-page'>
        <div className={cn('grid grid-rows-[auto] gap-10', 'md:w-auto md:grid-cols-2')}>
          <ProjectMap />
          <ProjectOverview />
        </div>

        <Stack direction='row' className='my-10 justify-start overflow-auto sm:justify-center'>
          <Tab to={buildPath(pathSet.v1_2_landMonitoring, { pathParams: { projectId } })}>
            {t('shared.projects.labels.landMonitoring')}
          </Tab>
          <Tab to={buildPath(pathSet.v1_2_nca, { pathParams: { projectId } })}>
            {t('shared.projects.labels.naturalCapitalAccount')}
          </Tab>
          <Tab to={buildPath(pathSet.v1_2_plots, { pathParams: { projectId } })}>
            <Stack direction='row' spacing={2} center>
              {t('shared.projects.labels.plots')}
              {invalidPlotsCount > 0 && (
                <span
                  data-testid='invalid-plots-count'
                  className='typography-body2Semibold flex aspect-auto items-center justify-center rounded-full bg-warning px-2 text-primary-100'
                >
                  {invalidPlotsCount}
                </span>
              )}
            </Stack>
          </Tab>
          <Tab to={buildPath(pathSet.v1_2_impactProjections, { pathParams: { projectId } })}>
            {t('shared.projects.labels.impactProjections')}
          </Tab>
        </Stack>
        <Stack className='my-6'>
          <Notices />
        </Stack>

        <Outlet />
      </Container>
    </SignedInPageLayout>
  );
};

const Tab = ({ className, to, ...delegated }: LinkProps) => {
  const location = useLocation();
  const isTabActive = location.pathname === to;

  const commonStyles = 'typography-button1 block border-b-2 p-4 whitespace-nowrap';

  if (isTabActive)
    return <span className={cn(commonStyles, 'border-b-secondary-100 text-primary-100', className)} {...delegated} />;

  return (
    <Link
      to={to}
      preventScrollReset
      className={cn(commonStyles, 'border-transparent text-text-secondary', className)}
      {...delegated}
    />
  );
};
