import { useSuspenseQuery } from '@tanstack/react-query';

import { Project } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';

export const getProjectById = async (projectId: Project['id']) => {
  const module = await import(`../mockData/${projectId}/project.json`);

  return module.default as Project;
};

export const useProject = () => {
  const membershipType = useMembershipType();
  const projectId = useProjectId();

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectId],
    queryFn: () => getProjectById(projectId),
  });
};
