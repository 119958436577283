import { theme } from 'tw-component-library/utils';

import { Fact } from '@/api/rest/resources/types/fact';

const resolveColor = (key: string) => {
  const themeColors = theme.colors as Record<string, string>;
  // eslint-disable-next-line security/detect-object-injection
  return themeColors[key] as string;
};

export const getTrendIconColor = (fact: Fact | undefined) => {
  if (!fact) {
    return null;
  }
  if ((fact.value as number) === 0) return null;
  if ((fact.value as number) > 0) return resolveColor('success');
  return resolveColor('error');
};

export const getTrendTextColor = (fact: Fact | undefined) => {
  if (!fact) {
    return null;
  }
  if ((fact.value as number) === 0) return resolveColor('text-primary');
  if ((fact.value as number) > 0) return resolveColor('success-dark');
  return resolveColor('error-dark');
};
