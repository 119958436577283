import 'mapbox-gl/dist/mapbox-gl.css';

import { cn, Container } from '@landler/tw-component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, LayerProps, MapLayerMouseEvent, MapRef, Source } from 'react-map-gl';

import { Form, Map, SecondaryNav } from '@/components';
import { MAP_MAX_ZOOM, MAP_OVERVIEW_PADDING_MOBILE } from '@/config/constants';
import { usePathSet } from '@/hooks/usePathSet';
import { SignedInPageLayout } from '@/layout/signed-in-page';
import { useMenuItems } from '@/pages/shared/hooks/useMenuItems';
import { buildPath } from '@/utils/buildPath';
import { isTestEnv } from '@/utils/isTestEnv';

import { useProject } from '../../components/conservation/hooks/useProject';
import {
  useProjectBoundary,
  useProjectBounds,
  useProjectCells,
} from '../../components/conservation/hooks/useProjectPolygon';
import { Controls } from './components/Controls';
import { HabitatThreatLayers } from './components/HabitatThreatLayers';
import { InfoPanel } from './components/InfoPanel';
import { BinaryLegend } from './components/Legend';
import { COLORS, PROJECT_BOUNDARY_SOURCE_ID, PROJECT_CELLS_SOURCE_ID } from './constants';
import { useControlsForm } from './hooks/useControlsForm';
import { CellFeature } from './types';

export const InteractiveMap = () => {
  const { t } = useTranslation();

  const menuItems = useMenuItems();
  const { data: project } = useProject();
  const pathSet = usePathSet();

  /**
   * The Map's onLoad callback is not triggered in the test environment, and so
   * the map remains hidden. We set the default value of isMapReady to true in
   * the test environment.
   */
  const [isMapReady, setIsMapReady] = useState(isTestEnv);

  const mapRef = useRef<MapRef | null>(null);

  const controlsForm = useControlsForm({
    defaultValues: {
      year: '2023',
      threatFilters: [],
    },
  });

  const [year, cell, threatFilters] = controlsForm.watch(['year', 'cell', 'threatFilters']);

  const { data: projectBounds } = useProjectBounds();
  const { data: projectBoundary } = useProjectBoundary();
  const { data: projectCells } = useProjectCells({ year });

  const handleMapClick = (event: MapLayerMouseEvent) => {
    const map = mapRef.current;

    if (!map) {
      return;
    }

    const feature = event.features?.[0] as CellFeature | undefined;

    if (!feature?.id) return;

    if (cell != null) {
      map.setFeatureState({ source: PROJECT_CELLS_SOURCE_ID, id: cell }, { active: false });
    }

    controlsForm.setValue('cell', feature.id as number);
    map.setFeatureState({ source: PROJECT_CELLS_SOURCE_ID, id: feature.id }, { active: true });
  };

  const hoveredPolygonIdRef = useRef<number | null>(null);

  const handleMapMouseMove = (event: MapLayerMouseEvent) => {
    const map = mapRef.current;

    if (!map) return;

    const feature = event.features?.[0];

    if (!feature?.id) {
      map.getCanvas().style.cursor = 'initial';

      if (hoveredPolygonIdRef.current !== null) {
        map.setFeatureState(
          { source: PROJECT_CELLS_SOURCE_ID, id: hoveredPolygonIdRef.current as number },
          { hover: false },
        );
        hoveredPolygonIdRef.current = null;
      }
    } else {
      map.getCanvas().style.cursor = 'pointer';

      if (hoveredPolygonIdRef.current !== null) {
        map.setFeatureState(
          { source: PROJECT_CELLS_SOURCE_ID, id: hoveredPolygonIdRef.current as number },
          { hover: false },
        );
      }

      hoveredPolygonIdRef.current = feature.id as number;
      map.setFeatureState(
        { source: PROJECT_CELLS_SOURCE_ID, id: hoveredPolygonIdRef.current as number },
        { hover: true },
      );
    }
  };

  const initialViewState = {
    bounds: projectBounds,
    fitBoundsOptions: { padding: MAP_OVERVIEW_PADDING_MOBILE, maxZoom: MAP_MAX_ZOOM },
  };

  return (
    <SignedInPageLayout items={menuItems} withCustomContentContainer>
      <SecondaryNav
        title={project.name}
        backPath={buildPath(pathSet.v1_2_landMonitoring, { pathParams: { projectId: project.id } })}
      />
      <Form {...controlsForm}>
        <Container
          gutterWidth={{ sm: 4, md: 10 }}
          className='h-[calc(100%_-_var(--footer-height))] py-8'
          data-testid='conservation-interactive-map-page'
        >
          <div
            className={cn(
              'relative h-full w-full overflow-hidden rounded-3xl bg-transparent',
              !isMapReady && 'animate-pulse bg-info-light',
            )}
          >
            <Map
              ref={mapRef}
              data-testid='conservation-interactive-map-gl'
              initialViewState={initialViewState}
              onLoad={(e) => {
                /**
                 * The map randomly renders half-blank.
                 * The following steps prevent that from happening.
                 */
                e.target.resize();
                e.target.fitBounds(initialViewState.bounds, initialViewState.fitBoundsOptions);

                setIsMapReady(true);
              }}
              onClick={handleMapClick}
              onMouseMove={handleMapMouseMove}
              interactiveLayerIds={projectCells && [cellsFillLayer.id as string]}
              style={{ opacity: isMapReady ? 1 : 0, transition: 'opacity 0.2s', width: '100%', height: '100%' }}
              logoPosition='bottom-left'
            >
              <Source id={PROJECT_BOUNDARY_SOURCE_ID} type='geojson' data={projectBoundary}>
                <Layer {...projectBoundaryLayer} />
              </Source>
              {projectCells && (
                <Source id={PROJECT_CELLS_SOURCE_ID} type='geojson' data={projectCells}>
                  <Layer {...cellsOutlineLayer} />
                  <Layer {...cellsFillLayer} />
                </Source>
              )}

              <HabitatThreatLayers />

              <Controls className='absolute left-6 top-6' />

              {threatFilters.length && (
                <div className='absolute bottom-6 left-6'>
                  <BinaryLegend
                    title={t('shared.projects.project.conservation.interactiveMap.labels.habitatIntactness')}
                    layers={threatFilters.map((threat) => ({
                      label: t(`shared.projects.project.conservation.interactiveMap.labels.${threat}`),
                      // eslint-disable-next-line security/detect-object-injection, @typescript-eslint/no-explicit-any
                      color: (COLORS as any)[threat].binary as string,
                    }))}
                    infoPopoverProps={{
                      title: t('shared.projects.project.conservation.interactiveMap.labels.habitatIntactness'),
                      body: t('shared.projects.project.conservation.interactiveMap.explainers.habitatIntactness'),
                    }}
                  />
                </div>
              )}

              {cell && (
                <div className='absolute right-0 top-0 h-full'>
                  <InfoPanel />
                </div>
              )}
            </Map>
          </div>
        </Container>
      </Form>
    </SignedInPageLayout>
  );
};

const projectBoundaryLayer: LayerProps = {
  id: 'feat-project-boundary',
  type: 'line',
  source: PROJECT_BOUNDARY_SOURCE_ID,
  paint: {
    'line-width': 1,
    'line-opacity': 0.6,
    'line-color': 'rgb(255, 255, 255)',
  },
};

const cellsOutlineLayer: LayerProps = {
  id: 'feat-cells-outline',
  type: 'line',
  source: PROJECT_CELLS_SOURCE_ID,
  paint: {
    'line-width': 1,
    'line-opacity': 0.6,
    'line-color': 'rgb(255, 255, 255)',
  },
};

const cellsFillLayer: LayerProps = {
  id: 'feat-cells-fill',
  type: 'fill',
  interactive: true,
  source: PROJECT_CELLS_SOURCE_ID,
  paint: {
    'fill-color': '#ffffff',
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'active'], false],
      0.9,
      ['boolean', ['feature-state', 'hover'], false],
      0.5,
      0.0,
    ],
  },
};
