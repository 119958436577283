import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import {
  FactsByFactType,
  GET_BUYER_DASHBOARD_PLOT_REPORTS_PATH,
  GET_DASHBOARD_PLOT_REPORTS_PATH,
  GetBuyerDashboardPlotReportsErrorDto,
  GetBuyerDashboardPlotReportsSuccessDto,
  GetDashboardPlotReportsErrorDto,
  GetDashboardPlotReportsSuccessDto,
  PlotReport,
} from '@/api/rest/resources/types/dashboard';
import { Fact } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useMembershipType } from '@/hooks/useMembershipType';

type SuccessDto = GetDashboardPlotReportsSuccessDto | GetBuyerDashboardPlotReportsSuccessDto;
type ErrorDto = GetDashboardPlotReportsErrorDto | GetBuyerDashboardPlotReportsErrorDto;

export type ReportByPlot = Record<string, FactsByFactType>;

export const getPlotReportsForProject = async ({
  projectId,
  membershipType,
}: {
  projectId: string;
  membershipType: MembershipWithOrganizationTypeEnum;
}) => {
  const factsReducer = (acc: FactsByFactType, curr: Fact) => ({ ...acc, [curr.name]: curr });

  const reportsReducer = (acc: ReportByPlot, curr: PlotReport) => ({
    ...acc,
    [curr.plot_id]: curr.facts.reduce(factsReducer, {} as FactsByFactType),
  });

  return (
    await getRequest<SuccessDto>(
      membershipType === MembershipWithOrganizationTypeEnum.land_steward
        ? GET_DASHBOARD_PLOT_REPORTS_PATH
        : GET_BUYER_DASHBOARD_PLOT_REPORTS_PATH,
      { project_id: projectId },
      undefined,
    )
  ).plot_reports.reduce(reportsReducer, {} as ReportByPlot);
};

export const usePlotReportsForProject = ({ projectId }: { projectId: string }) => {
  const membershipType = useMembershipType();
  return useSuspenseQuery<ReportByPlot, ErrorDto>({
    queryKey: [membershipType, 'dashboard', 'plot-reports', projectId],
    queryFn: () => getPlotReportsForProject({ projectId, membershipType }),
    staleTime: 1000 * 60 * 5,
  });
};
