import { ReactComponent as MapPinBig } from '@assets/images/map-pin-big.svg';
import { ReactComponent as MapPinBigActive } from '@assets/images/map-pin-big-active.svg';
import projectThumbnailSrc from '@assets/images/project-thumbnail.png';
import { Image, RiArrowRightSLine, Stack } from '@landler/tw-component-library';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LngLatLike, Marker, Popup } from 'react-map-gl';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { ProjectStatus } from '@/api/rest/resources/types/project';
import { MaybeLink } from '@/components';
import { ProjectThumbnail } from '@/components/MapThumbnail/MapThumbnail';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useMembershipType } from '@/hooks/useMembershipType';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { squareMetersToHectares } from '@/utils/plot';

import { usePlotsForProject } from '../../../../hooks/usePlotsForProject';
import { ProjectListEnhanced, ProjectListEnhancedWithCentroid } from '../../../../hooks/useProjectsList';

export type MarkersProps = {
  projects: ProjectListEnhanced[];
  onMarkerClick?: (lngLat: LngLatLike) => void;
};

export const Markers: FC<MarkersProps> = ({ projects, onMarkerClick }) => {
  const [selectedProject, setSelectedProject] = useState<ProjectListEnhancedWithCentroid | null>(null);

  const projectsWithCentroid = projects.filter((project) => project.centroid) as ProjectListEnhancedWithCentroid[];

  return (
    <>
      {projectsWithCentroid.map((project) => (
        <Marker
          key={project.id}
          anchor='center'
          latitude={project.centroid.lat}
          longitude={project.centroid.lon}
          onClick={(e) => {
            e?.originalEvent?.stopPropagation?.();

            const isSamePlot = project.id === selectedProject?.id;

            if (isSamePlot) return setSelectedProject(null);

            onMarkerClick?.({ lng: project.centroid.lon, lat: project.centroid.lat });
            return setSelectedProject(project);
          }}
        >
          {project.id === selectedProject?.id ? (
            <MapPinBigActive
              data-testid={`pin-${project.id}`}
              data-cy={`pin-${project.id}`}
              className='h-14 w-14 cursor-pointer'
            />
          ) : (
            <MapPinBig
              data-testid={`pin-${project.id}`}
              data-cy={`pin-${project.id}`}
              className='h-12 w-12 cursor-pointer'
            />
          )}
        </Marker>
      ))}
      {selectedProject && (
        <Popup
          anchor='top'
          data-testid={`popup-${selectedProject.id}`}
          longitude={selectedProject.centroid.lon}
          latitude={selectedProject.centroid.lat}
          closeButton={false}
          onClose={() => setSelectedProject(null)}
          maxWidth='none'
          offset={24}
        >
          <PopupContent project={selectedProject} />
        </Popup>
      )}
    </>
  );
};

type PopupContentProps = { project: ProjectListEnhanced };

const PopupContent: FC<PopupContentProps> = ({ project }) => {
  const { t } = useTranslation();
  const membershipType = useMembershipType();

  const area = useDisplayNumber(squareMetersToHectares(project.area));

  const destinationPath = useMemo(() => {
    if (membershipType === MembershipWithOrganizationTypeEnum.buyer) {
      if (project.status === ProjectStatus.initial) return null;

      return buildPath(paths.buyer.projectDetails, {
        pathParams: { projectId: project.id },
      });
    }

    return buildPath(paths.landSteward.projectDetails, {
      pathParams: { projectId: project.id },
    });
  }, [project, membershipType]);

  return (
    <Stack className='items-center animate-in fade-in slide-in-from-top-2' data-cy={`popup-${project.id}`}>
      <div className='-left-3 h-6 w-6 rotate-45 rounded bg-white-100' data-cy='content' />
      <Stack className='w-[430px] max-w-[90vw] -translate-y-4 rounded-2xl bg-white-100 px-4 pb-6 pt-4' spacing={6}>
        <MaybeLink to={destinationPath}>
          <Stack
            direction='row'
            className='items-center justify-between rounded-lg p-2 hover:bg-neutral-hover'
            data-cy='open-details-row'
          >
            <Stack direction='row' className='min-w-0 items-center'>
              <Thumbnail projectId={project.id} />
              <InfoBox label={t('shared.projects.map.popup.labels.project')} value={project.name} data-cy='name' />
            </Stack>
            <RiArrowRightSLine size={24} className='shrink-0' />
          </Stack>
        </MaybeLink>
        <Stack direction='row' spacing={10} className='px-2'>
          <div className='flex-shrink-0'>
            <InfoBox label={t('shared.projects.map.popup.labels.area')} value={`${area} ha`} data-cy='area' />
          </div>
          <InfoBox
            label={t('shared.projects.map.popup.labels.location')}
            value={project.location_description || t('shared.projects.map.popup.unknownLocation')}
            data-cy='location'
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const InfoBox: FC<{ label: string; value: string; 'data-cy': string }> = ({ label, value, 'data-cy': dataCy }) => (
  <Stack className='min-w-0' data-cy={`${dataCy}-info-box`}>
    <span className='typography-overline block truncate pb-2 text-left text-text-disabled' data-cy='label'>
      {label}
    </span>
    <span className='typography-body1 block truncate whitespace-nowrap text-left' data-cy='value'>
      {value}
    </span>
  </Stack>
);

const Thumbnail: FC<{ projectId: string }> = ({ projectId }) => {
  const plots = usePlotsForProject(projectId).data.results;

  if (!plots) {
    return <Image src={projectThumbnailSrc} className='mr-4 h-12 w-12 rounded' alt='project-thumbnail' />;
  }

  return <ProjectThumbnail plots={plots} className='mr-4 h-12 w-12 rounded' data-cy='thumbnail' />;
};
