import { cn, Shimmer, Stack } from '@landler/tw-component-library';
import i18next from 'i18next';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useGetBuyerPlots } from '@/api/rest/resources/plot';
import { useGetBuyerProjectById } from '@/api/rest/resources/project';
import { ContractList } from '@/api/rest/resources/types/contract';
import { ProjectThumbnail } from '@/components/MapThumbnail/MapThumbnail';
import { useDisplayCurrency } from '@/hooks/useDisplayCurrency';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { paths } from '@/routing';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

import { useBuyerContract } from '../../../hooks/useBuyerContract';

export const List = () => {
  const { t } = useTranslation();

  const { data } = useBuyerContract();

  if (!data) {
    throw new UnexpectedMissingDataError({ dataLabel: 'buyerContract' });
  }

  return (
    <Stack spacing={6} data-cy='investments-contracts-list'>
      <span className='typography-overline' data-cy='investments-contracts-list-title'>
        {t('buyer.investments.labels.activeInvestmentContracts')}
      </span>
      <Stack spacing={4} data-cy='investments-contracts-list-row'>
        {data.results.map((contract, index) => (
          <ListItem key={`${contract.project}_${index}`} {...contract} />
        ))}
      </Stack>
    </Stack>
  );
};

const ListItem: FC<ContractList> = ({
  project: projectId,
  start_date: startDate,
  end_date: endDate,
  carbon_storage_claims: carbonStorageClaims,
  water_holding_capacity_claims: waterHoldingCapacityClaims,
  total_investment: totalInvestment,
}) => {
  const { t } = useTranslation();
  const projectQuery = useGetBuyerProjectById(
    { pathVariables: { id: projectId } },
    { throwOnError: true, staleTime: 1000 * 60 * 5 },
  );
  const project = projectQuery.data;

  const plotsQuery = useGetBuyerPlots(
    { queryParams: { project: projectId } },
    { throwOnError: true, staleTime: 1000 * 60 * 5 },
  );
  const plots = plotsQuery.data?.results;

  const formattedStartDate = new Date(startDate).toLocaleDateString(i18next.language);
  const formattedEndDate = new Date(endDate).toLocaleDateString(i18next.language);
  const formattedCarbonStorage = useDisplayNumber(carbonStorageClaims ?? 0);
  const formattedWaterHoldingCapacity = useDisplayNumber(waterHoldingCapacityClaims ?? 0);
  const formattedTotalInvestment = useDisplayCurrency(totalInvestment ?? 0);

  return (
    <div className='rounded-lg bg-white-100 px-6 py-4'>
      <div className='grid w-full grid-cols-none gap-4 divide-y divide-divider lg:grid-cols-12 lg:gap-0 lg:divide-x lg:divide-y-0'>
        <div className='col-span-12 flex flex-row items-center gap-4 lg:col-span-3'>
          {plots ? (
            <ProjectThumbnail plots={plots} className='h-16 w-16 rounded-lg' data-cy='contract-project-image' />
          ) : (
            <Shimmer className='h-16 w-16 flex-shrink-0 rounded-lg' />
          )}
          <DataStack className='flex-col items-start pt-0 lg:pl-0'>
            <DataStack.Label data-cy='contract-project-name-label'>
              {t('buyer.investments.labels.projectName')}
            </DataStack.Label>
            <Link to={paths.buyer.projectDetails.replace(':projectId', projectId)}>
              <DataStack.Data className='hover:underline' data-cy='contract-project-name'>
                {project?.name}
              </DataStack.Data>
            </Link>
          </DataStack>
        </div>
        <div className='col-span-12 grid grid-cols-12 items-center gap-4 divide-y divide-divider lg:col-span-9 lg:grid-cols-12 lg:gap-0 lg:divide-x lg:divide-y-0'>
          <DataStack>
            <DataStack.Label data-cy='contract-start-date-label'>
              {t('buyer.investments.labels.startDate')}
            </DataStack.Label>
            <DataStack.Data data-cy='contract-start-date'>{formattedStartDate}</DataStack.Data>
          </DataStack>
          <DataStack>
            <DataStack.Label data-cy='contract-end-date-label'>{t('buyer.investments.labels.endDate')}</DataStack.Label>
            <DataStack.Data data-cy='contract-end-date'>{formattedEndDate}</DataStack.Data>
          </DataStack>
          <DataStack className='lg:col-span-3'>
            <DataStack.Label data-cy='contract-carbon-label'>{t('global.analysis.carbonStorageBg')}</DataStack.Label>
            <DataStack.Data data-cy='contract-carbon'>
              {t('buyer.investments.labels.upToUpliftUnits', { num: formattedCarbonStorage })}
            </DataStack.Data>
          </DataStack>
          <DataStack className='lg:col-span-3'>
            <DataStack.Label data-cy='contract-water-label'>
              {t('global.analysis.waterHoldingCapacity')}
            </DataStack.Label>
            <DataStack.Data data-cy='contract-water'>
              {t('buyer.investments.labels.upToUpliftUnits', { num: formattedWaterHoldingCapacity })}
            </DataStack.Data>
          </DataStack>
          <DataStack>
            <DataStack.Label data-cy='contract-investment-label'>
              {t('buyer.investments.labels.totalInvestment')}
            </DataStack.Label>
            <DataStack.Data data-cy='contract-investment'>{formattedTotalInvestment}</DataStack.Data>
          </DataStack>
        </div>
      </div>
    </div>
  );
};

const DataStack = ({ className, ...delegated }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        'col-span-12 flex flex-row items-center justify-between gap-4 pt-4',
        'lg:col-span-2 lg:flex-col lg:items-start lg:pl-4 lg:pt-0',
        className,
      )}
      {...delegated}
    />
  );
};

DataStack.Label = function SectionLabel({ className, ...delegated }: HTMLAttributes<HTMLDivElement>) {
  return <span className={cn('typography-overline text-text-secondary', className)} {...delegated} />;
};

DataStack.Data = function SectionData({ className, children, ...delegated }: HTMLAttributes<HTMLDivElement>) {
  if (!children) return <Shimmer className='h-5 w-36 rounded-full' data-testid='loading-widget' />;

  return (
    <span className={cn('typography-body1 text-right lg:text-left', className)} {...delegated}>
      {children}
    </span>
  );
};
