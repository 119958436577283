import { theme } from 'tw-component-library/utils';

import { PlotStatusEnum } from '@/api/rest/resources/types/plot';
import { Logger } from '@/lib/logs/logger';

const resolveColor = (key: string) => {
  const themeColors = theme.colors as Record<string, string>;
  // eslint-disable-next-line security/detect-object-injection
  return themeColors[key] as string;
};

export const getColorOfPlotStatus = (plotStatus: PlotStatusEnum) => {
  const exhaustivenessCheck = (status: never) => {
    Logger.error(`No color defined for plot status "${status}"`);
    return resolveColor('transparent');
  };
  switch (plotStatus) {
    case PlotStatusEnum.invalid:
      return resolveColor('error');
    case PlotStatusEnum.new_plot:
      return resolveColor('new-plot');
    case PlotStatusEnum.draft:
      return resolveColor('active-54');
    case PlotStatusEnum.ready_to_analyse:
      return resolveColor('warning');
    case PlotStatusEnum.analysed:
      return resolveColor('success');
    case PlotStatusEnum.calculating:
    case PlotStatusEnum.scheduled_for_analysis:
      return resolveColor('warning-light');
    default:
      return exhaustivenessCheck(plotStatus);
  }
};

export const getColorOfPlotStatusNotice = (plotStatus: PlotStatusEnum) => {
  const exhaustivenessCheck = (status: never) => {
    Logger.error(`No color defined for plot status notice "${status}"`);
    return resolveColor('transparent');
  };

  switch (plotStatus) {
    case PlotStatusEnum.invalid:
      return resolveColor('error-plot-background');
    case PlotStatusEnum.new_plot:
      return resolveColor('new-plot-light');
    case PlotStatusEnum.draft:
      return resolveColor('neutral-black-4');
    case PlotStatusEnum.analysed:
    case PlotStatusEnum.calculating:
    case PlotStatusEnum.ready_to_analyse:
    case PlotStatusEnum.scheduled_for_analysis:
      return resolveColor('transparent');
    default:
      return exhaustivenessCheck(plotStatus);
  }
};

export const getTextColorOfPlotStatusNotice = (plotStatus: PlotStatusEnum) => {
  const exhaustivenessCheck = (status: never) => {
    Logger.error(`No text color defined for plot status notice "${status}"`);
    return resolveColor('black-100');
  };

  switch (plotStatus) {
    case PlotStatusEnum.invalid:
      return resolveColor('error-dark');
    case PlotStatusEnum.new_plot:
      return resolveColor('new-plot-dark');
    case PlotStatusEnum.draft:
      return resolveColor('plot-status-draft-notice-text');
    case PlotStatusEnum.analysed:
    case PlotStatusEnum.calculating:
    case PlotStatusEnum.ready_to_analyse:
    case PlotStatusEnum.scheduled_for_analysis:
      return resolveColor('black-100');
    default:
      return exhaustivenessCheck(plotStatus);
  }
};

export const getColorOfPill = (isOutdatedValue: boolean) => {
  return isOutdatedValue ? resolveColor('neutral-black-12') : resolveColor('neutral-black-4');
};

export const getTextColorOfPill = (status: PlotStatusEnum) => {
  return status === PlotStatusEnum.analysed ? resolveColor('black-100') : resolveColor('secondary');
};
