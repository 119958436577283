import { useParams } from 'react-router-dom';

import { Fact, R1FactType } from '@/api/rest/resources/types/fact';

import { useDashboardByProjectId } from './useDashboardByProjectId';

/**
 * @param projectId Falls back to the projectId in the URL if no value is provided.
 */
export const useNCData = <T = unknown>(type: R1FactType, projectIdArg?: string) => {
  const routeParams = useParams();
  const projectId = projectIdArg ?? routeParams.projectId;

  const dashboardData = useDashboardByProjectId(projectId).data;

  // TODO: MVP-3284 refactor into useDashboard like plot-report
  const getFact = (factType: R1FactType) => {
    return dashboardData.facts.find((f) => f.name === factType) as Fact<T | null> | undefined;
  };

  if (!Object.keys(R1FactType).includes(type)) {
    throw Error(`Unrecognised type received: ${type}`);
  }

  return getFact(type);
};
