import { Layer, Source } from 'react-map-gl';

import { useTilesetsByLayer } from '../../../components/conservation/hooks/useTilesets';
import { COLORS } from '../constants';
import { useControlsContext } from '../hooks/useControlsForm';
import { ThreatFilter } from '../types';

export const HabitatThreatLayers = () => {
  return (
    <>
      <TreeLossLayer />
      <CropsLayer />
      <BuildingsLayer />
    </>
  );
};

const BuildingsLayer = () => {
  return <ThreatLayer threat='buildings' fillColor={COLORS.buildings.binary} />;
};

const CropsLayer = () => {
  return <ThreatLayer threat='crops' fillColor={COLORS.crops.binary} />;
};

const TreeLossLayer = () => {
  return <ThreatLayer threat='treeLoss' fillColor={COLORS.treeLoss.binary} />;
};

type ThreatLayerProps = {
  threat: ThreatFilter;
  fillColor: string;
};

const ThreatLayer = ({ threat, fillColor }: ThreatLayerProps) => {
  const controlsForm = useControlsContext();

  const [year, threatFilters] = controlsForm.watch(['year', 'threatFilters']);

  const { data: tilesetsByLayer } = useTilesetsByLayer();
  // eslint-disable-next-line security/detect-object-injection
  const tileset = tilesetsByLayer[threat]?.[year];

  if (!threatFilters.includes(threat)) {
    return null;
  }

  if (!tileset) {
    return null;
  }

  return (
    <Source
      key={tileset.id} // Force load tilesets
      id={threat}
      type='vector'
      url={`mapbox://${tileset.id}`}
    >
      <Layer
        id={`feat-${threat}-fill`}
        type='fill'
        source={threat}
        source-layer={tileset.sourceLayer}
        paint={{
          'fill-color': fillColor,
          'fill-opacity': ['case', ['==', ['get', 'DN'], 1], 0.8, 0.0],
        }}
      />
    </Source>
  );
};
