import { Loader } from '@landler/tw-component-library';
import React from 'react';

export type SuspenseBoundaryProps = React.SuspenseProps;

export const SuspenseBoundary = React.Suspense;

export const withSuspenseBoundary = (Component: () => JSX.Element, fallback?: React.ReactNode) => {
  return function Wrapped() {
    return (
      <SuspenseBoundary fallback={fallback ?? <Loader />}>
        <Component />
      </SuspenseBoundary>
    );
  };
};
