import { UnitEnum } from '@/api/rest/resources/types/units';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';

import { formatUnit } from './formatting';

type Dimension = 'hectares' | 'squareKilometers' | 'squareMeters';

export class Area {
  public value: number;

  public dimension: Dimension;

  #valueInSquareMeters: Area['value'];

  constructor({ value, dimension }: { value: Area['value']; dimension: Area['dimension'] }) {
    this.value = value;
    this.dimension = dimension;

    this.#valueInSquareMeters = this.#getValueInSquareMeters({ value, dimension });
  }

  #getValueInSquareMeters({ value, dimension }: { value: Area['value']; dimension: Area['dimension'] }) {
    if (dimension === 'squareKilometers') {
      return value * 1_000_000;
    }

    if (dimension === 'hectares') {
      return value * 10_000;
    }

    return value;
  }

  public toSquareMeters() {
    return new Area({ value: this.#valueInSquareMeters, dimension: 'squareMeters' });
  }

  public toSquareKilometers() {
    return new Area({
      value: this.#valueInSquareMeters / 1_000_000,
      dimension: 'squareKilometers',
    });
  }

  public toHectares() {
    return new Area({
      value: this.#valueInSquareMeters / 10_000,
      dimension: 'hectares',
    });
  }
}

export const useAreaDisplay = (area: Area) => {
  const valueDisplay = useDisplayNumber(area.value);

  const unitDisplay = formatUnit(
    (() => {
      const exhaustivenessCheck = (dimension: never) => {
        throw Error(`Unhandled dimension: ${dimension}`);
      };

      switch (area.dimension) {
        case 'hectares':
          return UnitEnum.ha;
        case 'squareKilometers':
          return UnitEnum['km^2'];
        case 'squareMeters':
          return UnitEnum['m^2'];

        default:
          return exhaustivenessCheck(area.dimension);
      }
    })(),
  );

  return `${valueDisplay} ${unitDisplay}`;
};
