import { cn, InfoPopover, InfoPopoverProps, Stack } from '@landler/tw-component-library';

type BinaryLayer = {
  label: string;
  color: string;
};

type BinaryLegendProps = {
  title: string;
  layers: BinaryLayer[];
  infoPopoverProps?: InfoPopoverProps;
};

export const BinaryLegend = ({ title, layers, infoPopoverProps }: BinaryLegendProps) => {
  return (
    <Stack className='rounded-2xl bg-white-100 p-4' spacing={3}>
      <Stack direction='row' spacing={2}>
        <span className='typography-body2Semibold text-text-secondary'>{title}</span>
        {infoPopoverProps && <InfoPopover position='top' {...infoPopoverProps} />}
      </Stack>

      <Stack direction='row' className='flex-wrap divide-x divide-divider'>
        {layers.map((layer, index) => (
          <Stack key={index} direction='row' centerMain spacing={2} className={cn('pr-2', index !== 0 && 'pl-2')}>
            <div className='h-2 w-2 rounded-full' style={{ backgroundColor: layer.color }} />
            <span className='typography-caption'>{layer.label}</span>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
