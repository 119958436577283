import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CapsuleTrend } from '@/components';
import { NCCardSectionHeader, printLatestDate, useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import {
  getNCChangeIcon,
  getNCChangeIconVariant,
  NCFactCapsule,
  NCFactCapsuleProps,
} from '@/components/NCFactCapsuleV1_2/NCFactCapsule';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useFact } from '@/hooks/useFact';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { formatKilogramsToTonnes } from '@/utils/formatting/formatKgToTon';

export const CarbonCardStatsTile = () => {
  return (
    <Stack className='gap-8 py-8 lg:gap-4' data-testid='carbon-stats-tile'>
      <BelowGroundCarbonStorage />
    </Stack>
  );
};

const BelowGroundCarbonStorage = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const carbonBgLatestAnalysis = formatKilogramsToTonnes(
    useNCData(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_latest_analysis_total
        : R1FactType.r1_carbon_bg_latest_analysis_per_ha,
    ),
  );
  const carbonBg1YearTrend = useNCData<number>(
    analysisType === 'total'
      ? R1FactType.r1_carbon_bg_previous_1y_uplift_total_pct_change
      : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha_pct_change,
  );
  const carbonBg1YearTrendDisplay = `${useDisplayNumber(carbonBg1YearTrend?.value ?? 0, { signDisplay: 'always' })} %`;

  const carbonBg1yrChange = formatKilogramsToTonnes(
    useNCData(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_previous_1y_uplift_total
        : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha,
    ),
  );

  const equivalentExplainer = useEquivalentsExplainer();

  const latestAnalysisDate = printLatestDate([carbonBgLatestAnalysis?.measured_at]);

  const latestAnalysisBody = t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
    date: latestAnalysisDate,
  });

  const popoverCarbonBgLatestAnalysis: NCFactCapsuleProps['infoPopoverProps'] = equivalentExplainer
    ? {
        title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
        body: `${latestAnalysisBody}\n${equivalentExplainer}`,
        popoverTriggerProps: {
          'data-testid': 'carbon-bg-latest-analysis-popover',
        },
      }
    : undefined;

  const carbonBg1YrChangeInitialMeasuredAt = printLatestDate([carbonBgLatestAnalysis?.measured_at]);
  const carbonBg1YrChangePreviousMeasuredAt = printLatestDate([carbonBg1yrChange?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.carbon.labels.belowTheGroundCarbonStorage')}
        supplementaryInfo={`${t('shared.ncaDetail.details.labels.latestAnalysis')} ${latestAnalysisDate}`}
        popover={{
          title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.latestAnalysis')}
          fact={carbonBgLatestAnalysis}
          trend={
            typeof carbonBg1YearTrend?.value === 'number' && (
              <CapsuleTrend variant={carbonBg1YearTrend.value < 0 ? 'negative' : 'positive'}>
                {carbonBg1YearTrendDisplay}
              </CapsuleTrend>
            )
          }
          capsuleIconProps={{
            icon: getNCChangeIcon(carbonBgLatestAnalysis),
            variant: getNCChangeIconVariant(carbonBgLatestAnalysis),
          }}
          infoPopoverProps={popoverCarbonBgLatestAnalysis}
        />
        {!!carbonBg1yrChange?.value && (
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.oneYearChange')}
            fact={carbonBg1yrChange}
            capsuleIconProps={{
              icon: getNCChangeIcon(carbonBg1yrChange),
              variant: getNCChangeIconVariant(carbonBg1yrChange),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.carbon.explainers.oneYearChange.title'),
              body: t('shared.ncaDetail.details.carbon.explainers.oneYearChange.body', {
                date: carbonBg1YrChangeInitialMeasuredAt,
                previousDate: carbonBg1YrChangePreviousMeasuredAt,
              }),
              popoverTriggerProps: {
                'data-testid': 'carbon-1yr-change-tooltip',
              },
            }}
          />
        )}
      </Stack>
    </div>
  );
};

const useEquivalentsExplainer = () => {
  const { t } = useTranslation();

  const { analysisType } = useNCCardContext();

  const carbonStorageBg = formatKilogramsToTonnes(
    useNCData(
      analysisType === 'total' ? R1FactType.r1_carbon_storage_bg_total : R1FactType.r1_carbon_storage_bg_per_ha,
    ),
  );
  const carbonStorageBgCo2Equivalent = formatKilogramsToTonnes(
    useNCData(
      analysisType === 'total'
        ? R1FactType.r1_carbon_storage_bg_co2_equivalent_total
        : R1FactType.r1_carbon_storage_bg_co2_equivalent_per_ha,
    ),
  );

  const { display: carbonStorageBgDisplay, value: carbonStorageBgValue } = useFact(carbonStorageBg);

  const { display: carbonStorageBgCo2EquivalentDisplay, value: carbonStorageBgCo2EquivalentValue } =
    useFact(carbonStorageBgCo2Equivalent);

  if (!carbonStorageBgValue || !carbonStorageBgCo2EquivalentValue) return undefined;

  return t('shared.ncaDetail.details.carbon.explainers.equivalents', {
    cValue: carbonStorageBgDisplay,
    co2Value: carbonStorageBgCo2EquivalentDisplay,
  });
};
