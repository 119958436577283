import { cn, theme } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, Label, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { colorPalette } from '@/theme/colorPalette';
import { formatUnit } from '@/utils/formatting';
import { printYear } from '@/utils/formatting/date';
import { valueToTonne } from '@/utils/formatting/formatKgToTon';

import { NCCardAnalysisType } from '../NCCardV1_2/NCCard';
import { ChartXTick, ChartYTick } from './components/Axis';
import { ChartLegendBar, ChartLegendBarItem } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { NoChartData } from './NoChartData';
import { DefaultChartProps } from './types';
import { getXAxisDomainForPotentialGraph, minMaxAxisDomain } from './utils';

const strokeColor = '#EAC745';
const gradientColor = '#FFDD5F';
const axisStrokeColor = (theme.colors as Record<string, string> | undefined)?.['neutral-black-12'];

export type CarbonStorageBGPotentialChartData = {
  date: string;
  name: 'carbon_storage';
  value: number;
  unit: 't';
};

type CarbonStorageBGPotentialChartProps = DefaultChartProps & {
  data: CarbonStorageBGPotentialChartData[];
  analysisType: NCCardAnalysisType;
};

export const CarbonStorageBGPotentialChart: FC<HTMLAttributes<HTMLDivElement> & CarbonStorageBGPotentialChartProps> = ({
  analysisType,
  data,
  height = 400,
  width = '100%',
  showTooltip = true,
  className,
  children,
  ...delegated
}) => {
  const styles = cn('flex w-full flex-col', className);

  const chartData = data.map((d) => ({
    label: new Date(d.date).getFullYear(),
    value:
      d.unit === UnitEnum.kg.toString() || d.unit === UnitEnum['kg/ha'].toString()
        ? valueToTonne(d.value, d.unit)
        : d.value,
  }));

  const unit = analysisType === 'total' ? formatUnit(UnitEnum.t) : formatUnit(UnitEnum['t/ha']);

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  const xTicks = getXAxisDomainForPotentialGraph(firstTick.label, lastTick.label);

  return (
    <div className={styles} {...delegated}>
      <ResponsiveContainer width={width} height={height}>
        <AreaChart
          data={chartData}
          // @ts-ignore overflow prop isn't included in the typings
          overflow='visible'
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id='carbonStorageBGPotential' x1='0' y1='-2.6' x2='0' y2='1'>
              <stop offset='0%' stopColor={gradientColor} stopOpacity={1} />
              <stop offset='100%' stopColor={gradientColor} stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='value'
            stroke={strokeColor}
            strokeWidth={3}
            fill='url(#carbonStorageBGPotential)'
            fillOpacity={1}
            activeDot={(props) => ChartTooltipDot(props)}
            connectNulls
          />
          <XAxis
            dataKey='label'
            tickLine={false}
            tick={<ChartXTick />}
            padding={{ right: 25 }}
            type='number'
            domain={['dataMin', 'dataMax']}
            stroke={axisStrokeColor}
            ticks={xTicks}
          />
          <YAxis
            dataKey='value'
            tickLine={false}
            type='number'
            scale='sequential'
            domain={minMaxAxisDomain(0.8, 1.2)}
            tick={<ChartYTick />}
            padding={{ top: 25 }}
            stroke={axisStrokeColor}
            width={65}
          >
            <Label className='typography-caption text-text-secondary' value={unit} position={{ x: 57, y: 10 }} />
          </YAxis>
          {showTooltip && (
            <Tooltip
              cursor={{ stroke: colorPalette.divider }}
              position={{ y: 0 }}
              allowEscapeViewBox={{ x: true }}
              animationDuration={150}
              content={(content) => <CustomTooltip {...content} unit={unit as UnitEnum} />}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
      <CustomLegendBar />
      {children}
    </div>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.belowGroundCarbonStorage'),
      color: gradientColor,
    },
  ];

  return (
    <ChartLegendBar items={legendBarItems} className='mt-8 sm:ml-[28px]' data-testid='carbon-storage-bg-chart-legend' />
  );
};

const CustomTooltip = ({ active, payload, label, unit }: TooltipProps<ValueType, NameType> & { unit: UnitEnum }) => {
  const displayValue = useDisplayNumber((payload?.[0]?.value as string) ?? 0);
  if (!active) return null;
  return (
    <ChartTooltip>
      <ChartTooltipTextPrimary>{`${displayValue} ${unit}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextSecondary>{printYear(`${label}`)}</ChartTooltipTextSecondary>
    </ChartTooltip>
  );
};
