import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CarbonStorageBGHistoricalChartData } from '@/components/ChartsV1_2';
import { CarbonStorageBGPotentialChart } from '@/components/ChartsV1_2/CarbonStorageBGPotential';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { useNCData } from '@/pages/shared/hooks/useNCData';

import { NoProjectData } from '../../../components/NoProjectData';

export const CarbonGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const carbonBgUpliftPotential = useNCData<CarbonStorageBGHistoricalChartData[]>(
    analysisType === 'total'
      ? R1FactType.r1_carbon_bg_uplift_potential_graph
      : R1FactType.r1_carbon_bg_uplift_potential_per_ha_graph,
  );

  const chartData = carbonBgUpliftPotential?.value;
  const noChartData = !chartData?.length;

  if (noChartData) {
    return <NoProjectData />;
  }

  return (
    <Stack center data-testid='carbon-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'>
        <ChartHeading
          heading={t('shared.ncaDetail.details.carbon.labels.upliftGraphHeadline')}
          popover={{
            // TODO: MVP-3284 Confirm if the popover copy is correct
            title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
            body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
          }}
        />
        <CarbonStorageBGPotentialChart
          analysisType={analysisType}
          data={chartData}
          showTooltip={true}
          color='#CCC'
          className='relative'
          data-testid='carbon-storage-bg-potential-chart'
        />
      </Stack>
    </Stack>
  );
};
