import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CarbonStorageBGHistoricalChart, CarbonStorageBGHistoricalChartData } from '@/components/ChartsV1_2';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';

import { NoPlotData } from '../NoPlotData';

export const CarbonGraphTile = () => {
  const { t } = useTranslation();
  const plot = usePlot().data;
  const { analysisType } = useNCCardContext();

  const { getFact } = usePlotReportForPlot({ plotId: plot.id });
  const carbonBgHistorical = getFact<CarbonStorageBGHistoricalChartData[]>(
    analysisType === 'total'
      ? R1FactType.r1_soil_carbon_historic_total_graph
      : R1FactType.r1_soil_carbon_historic_per_ha_graph,
  );

  const chartData = carbonBgHistorical?.value;
  const noChartData = !chartData?.length;

  if (noChartData) {
    return <NoPlotData />;
  }

  return (
    <Stack
      spacing={6}
      className='items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'
      data-testid='carbon-graph-tile'
    >
      <ChartHeading
        heading={t('shared.ncaDetail.details.carbon.labels.historicalGraphHeadline')}
        popover={{
          // TODO: MVP-3284 Confirm if the popover copy is correct
          title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
        }}
      />
      <CarbonStorageBGHistoricalChart
        analysisType={analysisType}
        data={chartData}
        showTooltip={true}
        className='relative'
        data-testid='carbon-storage-bg-chart'
      />
    </Stack>
  );
};
