export const useDisplayNumber = (input: number | string, options?: Intl.NumberFormatOptions) => {
  return getDisplayNumber(input, window.navigator.language, options);
};

/**
 * Rules:
 * - If number has 3 or more integer digits, no decimal digits are shown
 * - If number has 2 integer digits, one decimal digit is shown
 * - If number has 1 integer digit that is not zero, two decimal digits are shown
 * - If the integer digit of the number is zero, three decimal digits are shown
 * - Decimal digits are always trimmed without rounding
 */
export const getDisplayNumber = (input: number | string, language: string, options?: Intl.NumberFormatOptions) => {
  const numberSigned = widenIfExponential(input);

  if (Number.isNaN(numberSigned)) throw Error(`Invalid number received: ${input}`);

  const numberAbs = Math.abs(numberSigned);

  if (numberAbs === 0 || numberAbs >= 100) {
    return new Intl.NumberFormat(language, options)
      .formatToParts(Math.trunc(numberSigned))
      .map(({ type, value }) => {
        // if a group delimeter is detected, replace with thinspace
        if (type === 'group') {
          return `\u2009`;
        }
        return value;
      })
      .reduce((string, part) => string + part);
  }

  let fractionDigits = 3;

  if (numberAbs < 100 && numberAbs >= 10) {
    fractionDigits = 1;
  }

  if (numberAbs < 10 && numberAbs >= 1) {
    fractionDigits = 2;
  }

  const parts = numberSigned.toString().split('.');
  const integer = parts[0];
  const fraction = parts[1]?.substring(0, fractionDigits) ?? '0';

  const unroundedNumber = `${integer}.${fraction}`;

  return new Intl.NumberFormat(language, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    ...options,
  })
    .formatToParts(Number(unroundedNumber))
    .map(({ type, value }) => {
      // if a group delimeter is detected, replace with thinspace
      if (type === 'group') {
        return `\u2009`;
      }
      return value;
    })
    .reduce((string, part) => string + part);
};

/**
 * JavaScript uses low-precision exponential notation for numbers below 10^-7
 * and above 10^21. Widening the number is on a best-effort basis due to the
 * lost precision.
 */
const widenIfExponential = (input: number | string) => {
  const numberSigned = Number(input);

  if (numberSigned > 1e21 || numberSigned < 1e-7) {
    return Number(numberSigned.toFixed(6));
  }

  return numberSigned;
};
