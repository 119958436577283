import {
  Button,
  ButtonLink,
  ButtonProps,
  cn,
  Divider,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownItemProps,
  DropdownTrigger,
  IconButton,
  InfoPopover,
  Pill,
  RiCheckboxMultipleLine,
  RiEditLine,
  RiLineChartLine,
  RiMoneyEuroCircleLine,
  RiMore2Fill,
  RiTreasureMapLine,
  Stack,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@landler/tw-component-library';
import React, { FC, HTMLAttributes, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { OrganizationTypeEnum } from '@/api/rest/resources/types/organization';
import { PlotType } from '@/api/rest/resources/types/plot';
import { ProjectBuyerDetail, ProjectDetail, ProjectStatus } from '@/api/rest/resources/types/project';
import { KycEnum } from '@/api/rest/resources/types/user';
import {
  Capsule,
  CapsuleContent,
  CapsuleIcon,
  CapsuleLabel,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CopyLandStewardEmailButton,
  getNCChangeIcon,
  getNCChangeIconVariant,
  NCFactCapsule,
  PlotTypeIcon,
  ProjectStatusBadge,
} from '@/components';
import { KycDialog } from '@/components/KycDialog/kyc-dialog';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useUser } from '@/lib/auth';
import { EditProject } from '@/pages/shared/components/EditProject';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { exhaustivenessCheck } from '@/utils/exhaustivenessCheck';
import { formatKilogramsToTonnes } from '@/utils/formatting';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';

type FooterVariant = 'initial' | 'kyc' | 'proposeInvestment' | 'invested';
const maxDisplayCropCount = 10;
const disabledProjectTooltipLabel = 'global.tooltips.disabledLockedProject';
const editProjectLabel = 'shared.projects.overviewCard.labels.editProject';

export const ProjectOverview: React.FC = () => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenSize() === 'large';

  const membershipType = useMembershipType();

  const projectDetail = useProjectDetailById().data;
  const plots = usePlotsForProject().data.results;

  const [isEditingProject, setIsEditingProject] = useState(false);

  // NOTE: Using the kyc status from the user and not from the project detail response because the user gets updated after
  // NOTE: the verification process is completed, so the status automatically switches to in_progress.
  // NOTE: We could also refetch the project detail but this would cause an unnecessary request and loading state.
  const { kyc: kycStatus } = useUser();

  const deforestedPlotCount = useNCData<number>(R1FactType.r1_deforestation_occurred_since_2020);

  const hasWritePermission = getProjectPermissions(projectDetail).includes('write');
  const isDemoProject = projectDetail.organization.type === OrganizationTypeEnum.DEMO;
  const plotTypes = projectDetail.landtypes_allowed;
  const numPlots = plots.length;
  const numCroplandPlots = plots.filter((plot) => plot.type === PlotType.CROPLAND).length;

  const crops =
    numCroplandPlots && numCroplandPlots > 0 && projectDetail.crops && projectDetail.crops.length > 0
      ? projectDetail.crops.map((crop) => t(`global.crops.${crop}`)).sort()
      : undefined;

  const sponsorName = (projectDetail as ProjectDetail).buyer?.name;
  const sellerName = (projectDetail as ProjectBuyerDetail).organization.name;
  const locationName = projectDetail.location_description;

  const deforestationActivityText = useMemo(() => {
    if (deforestedPlotCount?.value == null) {
      return (
        <DataStackContent className='text-text-secondary'>
          {t('global.analysis.calculationInProgress')}
        </DataStackContent>
      );
    }

    if (deforestedPlotCount?.value === 0) {
      return (
        <DataStackContent>
          {t('shared.ncaDetail.details.deforestation.noDeforestationActivityDetected')}
        </DataStackContent>
      );
    }
    return (
      <DataStackContent>
        {t('shared.ncaDetail.details.deforestation.deforestationActivityCount', {
          affectedCount: deforestedPlotCount.value,
          totalCount: plots.length,
        })}
      </DataStackContent>
    );
  }, [t, deforestedPlotCount, plots.length]);

  const footerVariant = useMemo((): FooterVariant => {
    const projectStatus = projectDetail.status;
    const projectIsLocked = projectDetail.is_locked;

    const showKycPrompt =
      [KycEnum.not_verified, KycEnum.failed, KycEnum.in_progress].includes(kycStatus) && projectIsLocked;

    if (showKycPrompt) return 'kyc';

    if (projectIsLocked) return 'proposeInvestment';

    if (projectStatus === ProjectStatus.invested) return 'invested';

    return 'initial';
  }, [projectDetail, kycStatus]);

  return (
    <>
      <Stack spacing={6} data-testid='project-overview'>
        <span className='typography-overline block' data-cy='project-overview-title'>
          {t('shared.ncaDetail.details.labels.projectOverview')}
        </span>
        <Card>
          <CardHeader>
            <Stack direction='row' className='justify-between'>
              <Stack>
                <span className='typography-overline mb-2'>{t('shared.ncaDetail.details.labels.projectName')}</span>
                <span className='typography-h3 mr-2 whitespace-normal break-all' data-cy='project-name'>
                  {projectDetail.name}
                </span>
              </Stack>
              {membershipType === MembershipWithOrganizationTypeEnum.buyer &&
                (hasWritePermission ? (
                  <CopyLandStewardEmailButton email={(projectDetail as ProjectBuyerDetail).organization_email} />
                ) : (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant='outline' className='h-fit shrink-0' disabled>
                        {t('shared.projects.overviewCard.labels.emailLandSteward')}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent sideOffset={5} side='left'>
                      {t(disabledProjectTooltipLabel)}
                      <TooltipArrow className='fill-white-100' />
                    </TooltipContent>
                  </Tooltip>
                ))}
              {membershipType === MembershipWithOrganizationTypeEnum.land_steward &&
                (isLargeScreen ? (
                  <EditProjectButton
                    data-cy='disabled-edit-project-btn'
                    className='h-fit'
                    onClick={() => setIsEditingProject(true)}
                    disabled={!hasWritePermission}
                  />
                ) : (
                  <Dropdown>
                    <DropdownTrigger asChild>
                      <IconButton
                        className='h-fit border border-primary-100 text-primary-100'
                        data-testid='overview-more-button'
                      >
                        <RiMore2Fill size={24} />
                      </IconButton>
                    </DropdownTrigger>
                    <DropdownContent align='end'>
                      <EditProjectDropdownItem
                        onSelect={() => setIsEditingProject(true)}
                        disabled={!hasWritePermission}
                      />
                    </DropdownContent>
                  </Dropdown>
                ))}
            </Stack>
          </CardHeader>
          <CardContent className='grid grid-cols-1 gap-x-20 gap-y-6 md:grid-cols-[max-content_max-content_auto]'>
            <div
              className={cn(
                'flex flex-col gap-x-20 gap-y-6',
                'md:grid md:grid-flow-col md:grid-cols-1',
                'md:grid-cols-[max-content_max-content_auto] md:grid-rows-2',
                'md:[grid-column-end:_-1] md:[grid-column-start:_1]',
              )}
            >
              {membershipType === MembershipWithOrganizationTypeEnum.buyer && sellerName && (
                <DataStack>
                  <DataStackLabel>{t('shared.ncaDetail.details.labels.landSteward')}</DataStackLabel>
                  <DataStackContent>{sellerName}</DataStackContent>
                </DataStack>
              )}
              {membershipType === MembershipWithOrganizationTypeEnum.land_steward && sponsorName && (
                <DataStack>
                  <DataStackLabel>{t('shared.projects.overviewCard.labels.sponsor')}</DataStackLabel>
                  <DataStackContent>{sponsorName}</DataStackContent>
                </DataStack>
              )}
              {locationName && (
                <DataStack>
                  <DataStackLabel>{t('shared.ncaDetail.details.labels.location')}</DataStackLabel>
                  <DataStackContent data-cy='location-name'>{locationName}</DataStackContent>
                </DataStack>
              )}
              {plotTypes && (
                <DataStack>
                  <DataStackLabel data-cy='project-land-type-title'>
                    {t('shared.projects.overviewCard.labels.projectLandType')}
                  </DataStackLabel>
                  <DataStackContent>
                    {plotTypes.length > 1 ? (
                      t('shared.projects.overviewCard.mixedLandtypes')
                    ) : (
                      <Stack direction='row' spacing={1}>
                        <PlotTypeIcon type={plotTypes[0] as PlotType} size={20} />
                        <span data-cy='project-land-type-value'>
                          {t(`global.plotTypes.${plotTypes[0] as PlotType}`)}
                        </span>
                      </Stack>
                    )}
                  </DataStackContent>
                </DataStack>
              )}
              {numPlots > 0 && (
                <DataStack>
                  <DataStackLabel>{t('shared.ncaDetail.details.labels.numberOfPlots')}</DataStackLabel>
                  <DataStackContent data-cy='plot-counts'>
                    {t('shared.ncaDetail.details.labels.plotWithCount', { count: numPlots })}
                  </DataStackContent>
                </DataStack>
              )}
              {crops && (
                <DataStack className='col-start-3 row-[span_2] md:max-w-lg'>
                  <DataStackLabel>{t('shared.ncaDetail.details.labels.crops')}</DataStackLabel>
                  <Stack className='flex flex-row flex-wrap gap-2'>
                    {crops.slice(0, maxDisplayCropCount).map((crop, index) => (
                      <Pill key={index} size='small' className='whitespace-nowrap bg-warning-light'>
                        {crop}
                      </Pill>
                    ))}
                    {crops.length > maxDisplayCropCount && (
                      <Pill key='...' size='small' className='bg-white whitespace-nowrap'>
                        {t(`shared.ncaDetail.itemsMoreCount`, {
                          count: crops.length - maxDisplayCropCount,
                        })}
                      </Pill>
                    )}
                  </Stack>
                </DataStack>
              )}
            </div>
            <Divider style={{ gridColumnStart: 1, gridColumnEnd: -1 }} />
            <DataStack>
              <DataStackLabel>
                <Stack direction='row' spacing={1} className='items-center' data-cy='deforestation-activity'>
                  {t('shared.ncaDetail.details.deforestation.deforestationActivity')}
                  <InfoPopover position='top' body={t('shared.ncaDetail.details.deforestation.popoverBody')} />
                </Stack>
              </DataStackLabel>
              {deforestationActivityText}
            </DataStack>
            <Divider style={{ gridColumnStart: 1, gridColumnEnd: -1 }} />
            <ProjectStatusDetail />
          </CardContent>

          {membershipType === MembershipWithOrganizationTypeEnum.land_steward &&
            (isDemoProject ? null : <LandstewardFooter variant={footerVariant} />)}

          {membershipType === MembershipWithOrganizationTypeEnum.buyer && (isDemoProject ? null : <BuyerFooter />)}
        </Card>
      </Stack>
      <EditProject
        projectId={projectDetail.id}
        open={isEditingProject}
        onOpenChange={(open) => setIsEditingProject(open)}
      />
    </>
  );
};

const ProjectStatusDetail = () => {
  const { t } = useTranslation();

  const projectDetail = useProjectDetailById().data;

  const projectStatus = projectDetail.status;
  const lockedSinceDate = projectDetail.locked_since;
  const contractStartDate = projectDetail.contracts?.[0]?.start_date;
  const contractEndDate = projectDetail.contracts?.[0]?.end_date;

  return (
    <>
      <DataStack className='col-start-1'>
        <DataStackLabel>{t('shared.ncaDetail.details.labels.projectStatus')}</DataStackLabel>
        <ProjectStatusBadge project={projectDetail} data-testid='project-status-badge' data-cy='project-status-badge' />
      </DataStack>
      {projectDetail.is_locked && lockedSinceDate && (
        <DataStack>
          <DataStackLabel data-cy='locked-since-label'>
            {t('shared.ncaDetail.details.labels.lockedSince')}
          </DataStackLabel>
          <DataStackContent data-cy='locked-since-date'>
            {new Date(lockedSinceDate).toLocaleDateString()}
          </DataStackContent>
        </DataStack>
      )}
      {projectStatus === ProjectStatus.invested && (
        <>
          {contractStartDate && (
            <DataStack>
              <DataStackLabel>{t('shared.ncaDetail.details.labels.contractStartDate')}</DataStackLabel>
              <DataStackContent>{new Date(contractStartDate).toLocaleDateString()}</DataStackContent>
            </DataStack>
          )}
          {contractEndDate && (
            <DataStack>
              <DataStackLabel>{t('shared.ncaDetail.details.labels.contractEndDate')}</DataStackLabel>
              <DataStackContent>{new Date(contractEndDate).toLocaleDateString()}</DataStackContent>
            </DataStack>
          )}
        </>
      )}
    </>
  );
};

const LandstewardFooter = ({ variant }: { variant: FooterVariant }) => {
  const { t } = useTranslation();
  const [isKycDialogOpen, setIsKycDialogOpen] = useState(false);
  const { kyc: kycStatus } = useUser();
  const projectId = useProjectId();

  if (variant === 'kyc') {
    return (
      <>
        <CardFooter
          overline={t('shared.ncaDetail.footer.kyc.overline')}
          description={t('shared.ncaDetail.footer.kyc.description')}
          icon={<RiCheckboxMultipleLine size={24} />}
          cta={
            <Button
              disabled={kycStatus === KycEnum.in_progress}
              type='button'
              data-cy='cta-kyc-button'
              onClick={() => {
                setIsKycDialogOpen(true);
              }}
            >
              {t('shared.ncaDetail.footer.kyc.button')}
            </Button>
          }
        />
        <KycDialog open={isKycDialogOpen} onChange={setIsKycDialogOpen} />
      </>
    );
  }

  if (variant === 'proposeInvestment') {
    return (
      <CardFooter
        overline={t('shared.ncaDetail.footer.proposeInvestment.overline')}
        description={t('shared.ncaDetail.footer.proposeInvestment.description')}
        icon={<RiMoneyEuroCircleLine size={24} />}
        cta={
          <ButtonLink to={paths.landSteward.investments} type='button' data-cy='cta-invest-button'>
            {t('shared.ncaDetail.footer.proposeInvestment.button')}
          </ButtonLink>
        }
      />
    );
  }

  if (variant === 'invested') {
    return (
      <CardFooter
        overline={t('shared.ncaDetail.footer.invested.overline')}
        description={t('shared.ncaDetail.footer.invested.description')}
        icon={<RiLineChartLine size={24} />}
        cta={
          <ButtonLink to={paths.landSteward.investments} type='button'>
            {t('shared.ncaDetail.footer.invested.button')}
          </ButtonLink>
        }
      />
    );
  }

  if (variant === 'initial') {
    return (
      <CardFooter
        overline={t('shared.ncaDetail.footer.initial.overline')}
        description={t('shared.ncaDetail.footer.initial.description')}
        icon={<RiTreasureMapLine size={24} />}
        cta={
          <ButtonLink
            to={buildPath(paths.landSteward.plots, { pathParams: { projectId } })}
            type='button'
            data-cy='ncaDetail-button'
          >
            {t('shared.ncaDetail.footer.initial.button')}
          </ButtonLink>
        }
      />
    );
  }

  return exhaustivenessCheck(variant);
};

const BuyerFooter = () => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenSize() === 'large';

  const projectDetail = useProjectDetailById().data;

  const carbonStorageBg = formatKilogramsToTonnes(
    useNCData<number>(R1FactType.r1_carbon_bg_1year_uplift_potential_total),
  );
  const waterHoldingCapacity = useNCData<number>(R1FactType.r1_water_holding_capacity_1year_uplift_potential_total);

  return (
    <CardContent className='bg-neutral-black-10 py-0 full-bleed-x'>
      {projectDetail.status === ProjectStatus.invested ? (
        <Stack className='gap-6 p-6 sm:flex-row sm:items-center sm:justify-between'>
          <Capsule
            label={<CapsuleLabel>{t('shared.ncaDetail.details.labels.investments')}</CapsuleLabel>}
            content={<CapsuleContent>{t('shared.ncaDetail.details.labels.seeInvestmentDetails')}</CapsuleContent>}
            thumbnail={<CapsuleIcon variant='disabled' icon={<RiLineChartLine />} />}
          />

          <ButtonLink to={paths.buyer.investments}>{t('shared.ncaDetail.details.labels.investmentDetail')}</ButtonLink>
        </Stack>
      ) : (
        <Stack spacing={6} className='p-6'>
          <Stack direction='row' className='items-center justify-between'>
            <Stack direction='row' spacing={2} className='items-center'>
              <span className='typography-overline'>{t('shared.ncaDetail.details.labels.upliftPotential')}</span>
            </Stack>
            {isLargeScreen && (
              <Stack direction='row' spacing={2} className='items-center'>
                <span className='typography-body2'>{t('shared.ncaDetail.details.labels.howDoesThisWork')}</span>
                <InfoPopover
                  position='left'
                  body={t('shared.ncaDetail.details.upliftExplainer')}
                  popoverTriggerProps={{
                    className: 'text-primary-100 text-2xl',
                  }}
                />
              </Stack>
            )}
          </Stack>

          <Divider />

          <Stack className='justify-between gap-8 sm:flex-row sm:items-start'>
            <Stack direction='row' className='flex-wrap items-start gap-8 md:items-center md:gap-5'>
              <NCFactCapsule
                label={t('shared.ncaDetail.details.indicators.carbonStorageBg.label')}
                fact={carbonStorageBg}
                capsuleIconProps={{
                  icon: getNCChangeIcon(carbonStorageBg),
                  variant: getNCChangeIconVariant(carbonStorageBg),
                }}
                infoPopoverProps={{
                  title: t('shared.ncaDetail.details.indicators.carbonStorageBg.popoverTitle'),
                  body: t('shared.ncaDetail.details.indicators.carbonStorageBg.popoverBody'),
                }}
              />
              <NCFactCapsule
                label={t('shared.ncaDetail.details.indicators.waterHoldingCapacity.label')}
                fact={waterHoldingCapacity}
                capsuleIconProps={{
                  icon: getNCChangeIcon(waterHoldingCapacity),
                  variant: getNCChangeIconVariant(waterHoldingCapacity),
                }}
                infoPopoverProps={{
                  title: t('shared.ncaDetail.details.indicators.waterHoldingCapacity.popoverTitle'),
                  body: t('shared.ncaDetail.details.indicators.waterHoldingCapacity.popoverBody'),
                }}
              />
            </Stack>
            <ButtonLink to={paths.buyer.investments}>
              {t('shared.ncaDetail.details.labels.proposeInvestment')}
            </ButtonLink>
          </Stack>
        </Stack>
      )}
    </CardContent>
  );
};

const DataStack = Stack;

const DataStackLabel: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-overline mb-2 whitespace-nowrap', className)} {...delegated} />
);
const DataStackContent: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-h4', className)} {...delegated} />
);

const EditProjectButton: React.FC<ButtonProps> = (delegated) => {
  const { t } = useTranslation();

  if (!delegated.disabled) {
    return (
      <Button variant='outline' type='button' {...delegated} data-cy='edit-project-btn'>
        {t(editProjectLabel)}
      </Button>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant='outline' type='button' leftAdornment={<RiEditLine />} {...delegated}>
          {t(editProjectLabel)}
        </Button>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {t(disabledProjectTooltipLabel)}
        <TooltipArrow className='fill-white-100' />
      </TooltipContent>
    </Tooltip>
  );
};

const EditProjectDropdownItem: React.FC<DropdownItemProps> = (delegated) => {
  const { t } = useTranslation();

  if (!delegated.disabled) {
    return (
      <DropdownItem leftAdornment={<RiEditLine />} {...delegated}>
        {t(editProjectLabel)}
      </DropdownItem>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <DropdownItem leftAdornment={<RiEditLine />} {...delegated}>
          {t(editProjectLabel)}
        </DropdownItem>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {t(disabledProjectTooltipLabel)}
        <TooltipArrow className='fill-white-100' />
      </TooltipContent>
    </Tooltip>
  );
};
