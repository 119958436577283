import { theme } from 'tw-component-library/utils';

import { PlotType } from '@/api/rest/resources/types/plot';
import { Logger } from '@/lib/logs/logger';

export const getColorOfPlotType = (type: PlotType): string => {
  const resolveColor = (key: string) => {
    const themeColors = theme.colors as Record<string, string>;
    // eslint-disable-next-line security/detect-object-injection
    return themeColors[key] as string;
  };

  switch (type) {
    case PlotType.BARE_AND_SPARSE_VEGETATION:
      return resolveColor('landtypes-sparse-vegetation-main');
    case PlotType.BUILT_UP:
      return resolveColor('landtypes-built-up-environment-main');
    case PlotType.CROPLAND:
      return resolveColor('landtypes-cropland-main');
    case PlotType.GRASSLAND:
      return resolveColor('landtypes-grassland-main');
    case PlotType.HERBACEOUS_WETLAND:
      return resolveColor('landtypes-peatland-main');
    case PlotType.MANGROVES:
      return resolveColor('landtypes-mangroves-main');
    case PlotType.MOSS_AND_LICHENS:
      return resolveColor('landtypes-moss-and-lichens-main');
    case PlotType.SHRUBLAND:
      return resolveColor('landtypes-shrubland-main');
    case PlotType.SNOW_AND_ICE:
      return resolveColor('landtypes-snow-and-ice-main');
    case PlotType.TREE_COVER:
      return resolveColor('landtypes-forest-main');
    case PlotType.PERMANENT_WATER_BODIES:
      return resolveColor('landtypes-permanent-water-bodies-main');
    case PlotType.UNDEFINED:
      return resolveColor('text-secondary');
    default:
      Logger.error(`No color defined for plot type "${type}"`);
      return resolveColor('black-100');
  }
};
