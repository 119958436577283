import React, { ComponentProps } from 'react';

export const ChartLabel = (props: ComponentProps<'text'> & { value: React.ReactNode }) => {
  const { x, y, className, stroke, dx, dy, value } = props;
  return (
    <text x={x} y={y} dx={dx ?? 0} dy={dy ?? 0} fill={stroke} className={className} textAnchor='end'>
      {value}
    </text>
  );
};
