import { cn, Divider, Pill, Stack, StackProps } from '@landler/tw-component-library';
import React, { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Area, useAreaDisplay } from '@/utils/area';

import { useProject } from '../hooks/useProject';

const MAX_BIOMES_DISPLAY_COUNT = 10;

export const ProjectOverview: React.FC = () => {
  const { t } = useTranslation();

  const { data: project } = useProject();

  const areaDisplay = useAreaDisplay(new Area({ value: project.area, dimension: 'squareMeters' }).toSquareKilometers());

  return (
    <div
      className='grid-row grid auto-rows-min items-start overflow-hidden rounded-3xl bg-white-100'
      data-testid='conservation-project-overview'
    >
      <DataGroup className='items-start'>
        <DataStack>
          <DataStackLabel>{t('shared.projects.project.conservation.overview.labels.projectName')}</DataStackLabel>
        </DataStack>
        <DataStackContent>{project.name}</DataStackContent>
      </DataGroup>

      <Divider />

      <DataGroup className='grid grid-cols-1 gap-6 md:grid-cols-2'>
        <DataStack>
          <DataStackLabel>{t('shared.projects.project.conservation.overview.labels.management')}</DataStackLabel>
          <DataStackContent>{project.organization.name}</DataStackContent>
        </DataStack>

        <DataStack>
          <DataStackLabel>{t('shared.projects.project.conservation.overview.labels.projectSize')}</DataStackLabel>
          <DataStackContent>{`${areaDisplay}`}</DataStackContent>
        </DataStack>

        <DataStack>
          <DataStackLabel>{t('shared.projects.project.conservation.overview.labels.location')}</DataStackLabel>
          <DataStackContent>
            {project.location_description || t('global.plot.mapPopup.unknownLocation')}
          </DataStackContent>
        </DataStack>
      </DataGroup>

      <Divider />

      <DataGroup className='grid grid-cols-1 gap-6'>
        <DataStack>
          <DataStackLabel>{t('shared.ncaDetail.conservation.labels.biome')}</DataStackLabel>
          <DataStackContent>
            {project.biomes.slice(0, MAX_BIOMES_DISPLAY_COUNT).map((biome) => (
              <Pill key={biome} className='bg-warning-light' size='small'>
                {t(`shared.ncaDetail.conservation.biome.${biome}`)}
              </Pill>
            ))}
            {project.biomes.length > MAX_BIOMES_DISPLAY_COUNT && (
              <Pill className='bg-warning-light' size='small'>
                {t(`shared.ncaDetail.itemsMoreCount`, {
                  count: project.biomes.length - MAX_BIOMES_DISPLAY_COUNT,
                })}
              </Pill>
            )}
          </DataStackContent>
        </DataStack>
        <DataStack>
          <DataStackLabel>{t('shared.ncaDetail.conservation.labels.ecoregion')}</DataStackLabel>
          <DataStackContent>{t(`shared.ncaDetail.conservation.ecoregion.${project.ecoregion}`)}</DataStackContent>
        </DataStack>
      </DataGroup>
    </div>
  );
};
const DataGroup: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...delegated }) => (
  <div className={cn('min-w-0 p-6', className)} {...delegated} />
);

const DataStack = ({ className, ...delegated }: StackProps) => {
  return <Stack className={cn('min-w-0', className)} {...delegated} />;
};

const DataStackLabel: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-overline mb-2 text-text-secondary', className)} {...delegated} />
);
const DataStackContent: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-h3 min-w-0 break-words', className)} {...delegated} />
);
