import { Fact } from '@/api/rest/resources/types/fact';
import { NCABalance } from '@/api/rest/resources/types/ncaBalance';
import { UnitEnum } from '@/api/rest/resources/types/units';

const kilogramsToTonnes = (kilograms: number) => {
  return kilograms / 1000;
};

export const formatKilogramsToTonnes = <T>(fact?: Fact<T>) => {
  if (!fact) return fact;

  if (fact.unit === UnitEnum.kg || fact.unit === UnitEnum['kg/ha']) {
    return {
      ...fact,
      value: fact.value ? kilogramsToTonnes(Number(fact.value)) : fact.value,
      unit: fact.unit === UnitEnum.kg ? UnitEnum.t : UnitEnum['t/ha'],
    } as Fact<T>;
  }
  return fact;
};

// TODO: MVP-3284 better naming/refactoring or inherit r1fact/ncabalance
export const formatBalanceKilogramsToTonnes = <T>(balance?: NCABalance<T>) => {
  if (!balance) return balance;

  if (balance.unit === UnitEnum.kg || balance.unit === UnitEnum['kg/ha']) {
    return {
      ...balance,
      value: balance.value ? kilogramsToTonnes(Number(balance.value)) : balance.value,
      unit: balance.unit === UnitEnum.kg ? UnitEnum.t : UnitEnum['t/ha'],
    } as NCABalance<T>;
  }
  return balance;
};

export const valueToTonne = (fromValue?: number | null, fromUnit?: string) => {
  if (!fromValue) return fromValue;

  if (fromUnit === UnitEnum.kg || fromUnit === UnitEnum['kg/ha']) {
    return kilogramsToTonnes(fromValue);
  }
  return fromValue;
};

export const unitToTonne = (unit: UnitEnum) => {
  switch (unit) {
    case UnitEnum.kg:
      return UnitEnum.t;
    case UnitEnum['kg/ha']:
      return UnitEnum['t/ha'];
    default:
      return unit;
  }
};
