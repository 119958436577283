import {
  Alert,
  AlertBody,
  BiodiversityIcon,
  Button,
  ButtonLink,
  cn,
  Co2Icon,
  Pill,
  Popover,
  PopoverAnchor,
  PopoverContent,
  RiCircleFill,
  RiErrorWarningFill,
  RiPencilFill,
  Stack,
  toast,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
  WaterIcon,
} from '@landler/tw-component-library';
import { useQueryClient } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import { FC, HTMLAttributes, PropsWithChildren, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { TextInput } from 'tw-component-library/TextInput/TextInput';

import { RestApiBadRequestServerError } from '@/api/rest/resources/errors/RestApiBadRequestError';
import { usePatchPlot } from '@/api/rest/resources/plot';
import { Fact, R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { DraftPlot, Plot, PlotStatusEnum, PlotType } from '@/api/rest/resources/types/plot';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { Form, FormControl, FormField, FormLabel, FormMessage, PlotTypeIcon } from '@/components';
import { PlotThumbnail } from '@/components/MapThumbnail/MapThumbnail';
import { UNANALYZABLE_PLOT_STATUS_TYPES } from '@/config/constants';
import { getDisplayNumber, useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useMembershipType } from '@/hooks/useMembershipType';
import { Logger } from '@/lib/logs/logger';
import { useProject } from '@/pages/landsteward/hooks/useProject';
import { usePlotReportsForProject } from '@/pages/shared/hooks/usePlotReportsForProject';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { formatUnit, valueToTonne } from '@/utils/formatting';
import { printDDMMYYYYHHMM } from '@/utils/formatting/date';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';
import { squareMetersToHectares } from '@/utils/plot';
import {
  getColorOfPill,
  getColorOfPlotStatus,
  getColorOfPlotStatusNotice,
  getTextColorOfPill,
  getTextColorOfPlotStatusNotice,
} from '@/utils/plot/get-color-of-plot-status/getColorOfPlotStatus';

const maxDisplayCropCount = 3;

export const NameCell = ({ row }: CellContext<Plot, unknown> | CellContext<DraftPlot, unknown>) => {
  const membershipType = useMembershipType();
  const project = useProject().data;
  const hasWritePermissions = getProjectPermissions(project).includes('write');

  const { t } = useTranslation();
  const plot = row.original;

  const createdAt = printDDMMYYYYHHMM((plot as Plot).created_at) ?? '';
  const modifiedAt = printDDMMYYYYHHMM((plot as Plot).modified_at) ?? '';
  const analysedAt = printDDMMYYYYHHMM((plot as Plot).last_analyzed_at) ?? '';

  const editedAt =
    modifiedAt > createdAt
      ? t('shared.plots.tooltips.edited', { timestamp: modifiedAt })
      : t('shared.plots.tooltips.uploaded', { timestamp: createdAt });
  const lastActionHint = analysedAt ? t('shared.plots.tooltips.analysed', { timestamp: analysedAt }) : editedAt;

  const [isRenamingPlot, setIsRenamingPlot] = useState(false);
  const canEditPlot = membershipType === MembershipWithOrganizationTypeEnum.land_steward && hasWritePermissions;

  return (
    <Stack direction='row' centerMain spacing={3}>
      {'status' in plot && plot.status === PlotStatusEnum.invalid ? (
        <span className='flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-bg-light-grey'>
          <RiErrorWarningFill size={20} className='text-error' data-testid='alert-icon' />
        </span>
      ) : (
        <PlotThumbnail plot={plot} className='h-12 w-12 shrink-0 rounded-lg' />
      )}
      <Tooltip open={isRenamingPlot ? false : undefined}>
        <TooltipContent sideOffset={5} side='top'>
          {lastActionHint}
          <TooltipArrow />
        </TooltipContent>
        <TooltipTrigger asChild>
          <Stack direction='col' spacing={2} className='col-start-1 row-start-1 w-[150px] items-start'>
            <Stack direction='row' spacing={1} className='w-full justify-between'>
              {canEditPlot && isRenamingPlot ? (
                <Popover defaultOpen modal onOpenChange={(open) => setIsRenamingPlot(open)}>
                  <PopoverAnchor asChild>
                    <span className='max-w-full truncate'>{plot.name}</span>
                  </PopoverAnchor>
                  <PopoverContent className='w-[320px]'>
                    <RenamePlot plot={plot} onDismiss={() => setIsRenamingPlot(false)} />
                  </PopoverContent>
                </Popover>
              ) : (
                <span className='max-w-full truncate'>{plot.name}</span>
              )}
              {canEditPlot && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsRenamingPlot(true);
                  }}
                  title={t('shared.plots.renamePlot.editPlotName')}
                  className={cn('invisible', !isRenamingPlot && 'group-hover/plot-row:visible')}
                >
                  <RiPencilFill />
                </button>
              )}
            </Stack>
            {'status' in plot && (
              <Stack direction='row' spacing={2} className='typography-body2 items-center'>
                <RiCircleFill size={12} color={getColorOfPlotStatus(plot.status)} />
                <span className='max-w-[125px] truncate'>{t(`global.plotStatus.${plot.status}`)}</span>
              </Stack>
            )}
          </Stack>
        </TooltipTrigger>
      </Tooltip>
    </Stack>
  );
};

type RenamePlotProps = HTMLAttributes<HTMLFormElement> & { plot: Plot | DraftPlot; onDismiss: () => void };

export const RenamePlot = ({ plot, onDismiss, onClick, ...delegated }: RenamePlotProps) => {
  const membershipType = useMembershipType();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const patchPlotMutation = usePatchPlot({ mutationKey: [membershipType, 'plot', plot.id] });

  type FormFields = { 'plot-name': string };
  const submit = async (formFields: FormFields) => {
    try {
      await patchPlotMutation.mutateAsync({
        pathVariables: { id: plot.id },
        bodyData: { name: formFields['plot-name'] },
      });

      await queryClient.invalidateQueries({ queryKey: [membershipType, 'plots'] });
      toast({
        title: t('shared.plots.renamePlot.success'),
        type: 'success',
      });
      onDismiss();
    } catch (error) {
      if (error instanceof RestApiBadRequestServerError && typeof error.errors[0] === 'string') {
        form.setError('plot-name', { type: 'custom', message: error.errors[0] });

        return;
      }

      toast({
        title: t('global.ui.toast.errorToastFallbackTitle'),
        description: t('global.ui.toast.errorToastFallbackDescription'),
        type: 'error',
      });
    }
  };

  const form = useForm<FormFields>({ defaultValues: { 'plot-name': plot.name } });

  return (
    <Form {...form}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <form
        onSubmit={form.handleSubmit(submit)}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.(e);
        }}
        {...delegated}
      >
        <Stack>
          <FormField
            name='plot-name'
            control={form.control}
            rules={{ required: { value: true, message: t('shared.plots.renamePlot.error.empty') } }}
            render={({ field, fieldState }) => (
              <>
                <FormLabel className='typography-button2 mb-2.5 text-text-secondary'>
                  {t('shared.plots.renamePlot.editPlotName')}
                </FormLabel>
                <FormControl>
                  <TextInput
                    /* eslint-disable-next-line jsx-a11y/no-autofocus */
                    autoFocus
                    data-testid='plot-name-text-input'
                    onFocus={(e) => {
                      e.target.select();
                    }}
                    className='bg-white-100 py-[1.5rem]'
                    {...field}
                  />
                </FormControl>
                <FormMessage>{fieldState.error?.message}</FormMessage>
              </>
            )}
          />
          <Stack direction='row' spacing={2} className='mt-3 justify-end'>
            <Button type='button' variant='text' size='small' onClick={onDismiss} data-testid='dismiss-button'>
              {t('global.ui.buttons.cancel')}
            </Button>
            <Button type='submit' variant='contained' size='small' data-testid='submit-button'>
              {t('global.ui.buttons.save')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Form>
  );
};

export const AreaCell = ({ row }: CellContext<Plot, unknown> | CellContext<DraftPlot, unknown>) => {
  const plot = row.original;
  const isValid = 'status' in plot ? plot.status !== PlotStatusEnum.invalid : true;

  const areaInHectares = getDisplayNumber(squareMetersToHectares(plot.area), window.navigator.language);

  return <span className='whitespace-nowrap'>{isValid ? `${areaInHectares} ha` : '--'}</span>;
};

export const PlotTypeCell = ({ row }: CellContext<Plot, unknown>) => {
  const plot = row.original;
  const { t } = useTranslation();

  const plotTypeHint = useMemo(() => {
    const hasCrops = plot.type === PlotType.CROPLAND && plot.crops && plot.crops.length > 0;
    const crops = plot.crops
      .slice(0, maxDisplayCropCount)
      .map((crop) => t(`global.crops.${crop}`))
      .sort()
      .join(', ');
    return hasCrops ? t('shared.plots.tooltips.cropland', { crops }) : t(`global.plotTypes.${plot.type}`);
  }, [t, plot.type, plot.crops]);
  return (
    <Tooltip>
      <TooltipTrigger>
        <PlotTypeIcon type={plot.type} size={24} />
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='top'>
        {plotTypeHint}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};

export const BiodiversityZoneCell = ({ row }: CellContext<Plot, unknown>) => {
  const plot = row.original;
  const projectDetail = useProjectDetailById().data;
  const { data: plotReports } = usePlotReportsForProject({ projectId: projectDetail.id });

  const biodiversityZone = plotReports[plot.id]?.[R1FactType.r1_biodiversity_zone_percent] as Fact<number> | undefined;

  let formattedFact = '--';

  const value = useDisplayNumber(biodiversityZone?.value ?? 0);
  const unit = formatUnit(UnitEnum['%']);

  if (biodiversityZone?.value != null) {
    formattedFact = unit ? `${value} ${unit}` : `${value}`;
  }

  if (UNANALYZABLE_PLOT_STATUS_TYPES.includes(plot.status)) {
    return <PlotNotice {...plot} />;
  }

  const isOutdatedValue = formattedFact !== '--' && plot.status !== PlotStatusEnum.analysed;

  return (
    <FactTooltip showTooltip={biodiversityZone?.value === null && plot.status === PlotStatusEnum.ready_to_analyse}>
      <Pill
        data-testid='bio-cell'
        size='small'
        style={{
          backgroundColor: getColorOfPill(isOutdatedValue),
          color: getTextColorOfPill(plot.status),
        }}
        leftAdornment={<BiodiversityIcon size={12} color={getTextColorOfPill(plot.status)} />}
      >
        {formattedFact}
      </Pill>
    </FactTooltip>
  );
};

export const CarbonStorageBgCell = ({ row }: CellContext<Plot, unknown>) => {
  const plot = row.original;
  const projectDetail = useProjectDetailById().data;
  const { data: plotReports } = usePlotReportsForProject({ projectId: projectDetail.id });

  const carbonStorageBg = plotReports[plot.id]?.[R1FactType.r1_carbon_storage_bg_per_ha] as Fact<number> | undefined;

  let formattedFact = '--';

  const value = useDisplayNumber(valueToTonne(carbonStorageBg?.value, carbonStorageBg?.unit) ?? '');
  const unit = formatUnit(UnitEnum['t/ha']);

  if (carbonStorageBg?.value != null) {
    formattedFact = unit ? `${value} ${unit}` : `${value}`;
  }

  if (UNANALYZABLE_PLOT_STATUS_TYPES.includes(plot.status)) {
    return null;
  }
  const isOutdatedValue = formattedFact !== '--' && plot.status !== PlotStatusEnum.analysed;

  return (
    <FactTooltip showTooltip={carbonStorageBg?.value === null && plot.status === PlotStatusEnum.ready_to_analyse}>
      <Pill
        data-testid='carbon-cell'
        size='small'
        style={{
          backgroundColor: getColorOfPill(isOutdatedValue),
          color: getTextColorOfPill(plot.status),
        }}
        leftAdornment={<Co2Icon size={12} color={getTextColorOfPill(plot.status)} />}
      >
        {formattedFact}
      </Pill>
    </FactTooltip>
  );
};

export const WaterHoldingCapacityCell = ({ row }: CellContext<Plot, unknown>) => {
  const plot = row.original;
  const projectDetail = useProjectDetailById().data;
  const { data: plotReports } = usePlotReportsForProject({ projectId: projectDetail.id });

  const waterHoldingCapacity = plotReports[plot.id]?.[R1FactType.r1_water_holding_capacity_per_ha] as
    | Fact<number>
    | undefined;

  let formattedFact = '--';

  const value = useDisplayNumber(valueToTonne(waterHoldingCapacity?.value, waterHoldingCapacity?.unit) ?? '');
  const unit = formatUnit(UnitEnum['m^3/ha']);

  if (waterHoldingCapacity?.value != null) {
    formattedFact = unit ? `${value} ${unit}` : `${value}`;
  }
  if (UNANALYZABLE_PLOT_STATUS_TYPES.includes(plot.status)) {
    return null;
  }
  const isOutdatedValue = formattedFact !== '--' && plot.status !== PlotStatusEnum.analysed;

  return (
    <FactTooltip showTooltip={waterHoldingCapacity?.value === null && plot.status === PlotStatusEnum.ready_to_analyse}>
      <Pill
        data-testid='whc-cell'
        size='small'
        style={{
          backgroundColor: getColorOfPill(isOutdatedValue),
          color: getTextColorOfPill(plot.status),
        }}
        leftAdornment={<WaterIcon size={12} color={getTextColorOfPill(plot.status)} />}
      >
        {formattedFact}
      </Pill>
    </FactTooltip>
  );
};

type FactTooltipProps = PropsWithChildren & {
  showTooltip: boolean;
};

export const FactTooltip: FC<FactTooltipProps> = ({ children, showTooltip }) => {
  const { t } = useTranslation();
  if (showTooltip) {
    return (
      <Tooltip>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent sideOffset={5} side='top'>
          {t('shared.plots.tooltips.analyseProject')}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    );
  }
  return <>{children}</>;
};

export const PlotNotice = (plot: Plot) => {
  const { t } = useTranslation();
  const membershipType = useMembershipType();
  const { pathname } = useLocation();

  const projectId = useProjectId();
  const projectDetail = useProjectDetailById().data;
  const isProjectEditable = getProjectPermissions(projectDetail).includes('write');
  const plotStatus = plot.status;

  const noticeLabel = () => {
    const exhaustivenessCheck = (status: never) => {
      Logger.error(`No notices defined for plot status "${status}"`);
      return null;
    };

    switch (plotStatus) {
      case PlotStatusEnum.invalid:
        if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) {
          return `${t('shared.plots.plotNotice.invalidPlot.info')} ${t(
            'shared.plots.plotNotice.invalidPlot.action.info',
          )}`;
        }
        return t('shared.plots.plotNotice.invalidPlot.info');
      case PlotStatusEnum.new_plot:
        return t('shared.plots.plotNotice.newPlot.info');
      case PlotStatusEnum.draft:
        return t('shared.plots.plotNotice.draftPlot.info');
      case PlotStatusEnum.analysed:
      case PlotStatusEnum.calculating:
      case PlotStatusEnum.ready_to_analyse:
      case PlotStatusEnum.scheduled_for_analysis:
        return null;
      default:
        return exhaustivenessCheck(plotStatus);
    }
  };

  const actionLabel = () => {
    const exhaustivenessCheck = (status: never) => {
      Logger.error(`No actions defined for plot status "${status}"`);
      return null;
    };

    switch (plotStatus) {
      case PlotStatusEnum.invalid:
        return t('shared.plots.plotNotice.invalidPlot.action.label');
      case PlotStatusEnum.new_plot:
        return t('shared.plots.plotNotice.newPlot.action.label');
      case PlotStatusEnum.draft:
        return t('shared.plots.plotNotice.draftPlot.action.label');
      case PlotStatusEnum.analysed:
      case PlotStatusEnum.calculating:
      case PlotStatusEnum.ready_to_analyse:
      case PlotStatusEnum.scheduled_for_analysis:
        return null;
      default:
        return exhaustivenessCheck(plotStatus);
    }
  };
  return (
    <Alert
      className='max-w-[600px] overflow-visible p-0'
      style={{
        backgroundColor: getColorOfPlotStatusNotice(plotStatus),
        color: getTextColorOfPlotStatusNotice(plotStatus),
      }}
      data-testid='plot-list-action-notice'
    >
      <AlertBody className='flex h-12 min-w-0 flex-row items-center justify-between gap-2'>
        <span className='flex-1 truncate'>{noticeLabel()}</span>
        {membershipType === MembershipWithOrganizationTypeEnum.land_steward &&
          (isProjectEditable ? (
            <ButtonLink
              data-testid='plot-list-action-notice-cta'
              variant='text'
              className='typography-button2 px-2 py-2'
              style={{
                color: getTextColorOfPlotStatusNotice(plotStatus),
              }}
              state={{
                previousPath: pathname,
              }}
              onClick={(e) => e.stopPropagation()}
              to={buildPath(paths.landSteward.editPlot, { pathParams: { projectId, plotId: plot.id } })}
              preventScrollReset
            >
              {actionLabel()}
            </ButtonLink>
          ) : (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  data-testid='plot-list-action-notice-cta'
                  disabled
                  variant='text'
                  className='typography-button2 px-2 py-2'
                  style={{
                    color: getTextColorOfPlotStatusNotice(plotStatus),
                  }}
                >
                  {actionLabel()}
                </Button>
              </TooltipTrigger>
              <TooltipContent sideOffset={5} side='bottom'>
                {t('shared.projects.plot.tooltips.disabledEditOrDeletePlot')}
                <TooltipArrow className='fill-white-100' />
              </TooltipContent>
            </Tooltip>
          ))}
      </AlertBody>
    </Alert>
  );
};
