/* eslint-disable camelcase */
import { Navigate, Outlet, Route } from 'react-router-dom';

import { BuyerCompleteAccountPage } from '@/pages/buyer/pages/complete-account/complete-account.page';
import { AccountEditEmailPage } from '@/pages/shared/account/pages/edit-email/EditEmail.page';
import { AccountEditNamePage } from '@/pages/shared/account/pages/edit-name/EditName.page';
import { AccountEditPasswordPage } from '@/pages/shared/account/pages/edit-password/EditPassword.page';
import { AccountPage } from '@/pages/shared/account/pages/index/Account.page';
import { AccountLegalPage } from '@/pages/shared/account/pages/legal/AccountLegal.page';
import { AccountTeamPage } from '@/pages/shared/account/pages/team/AccountTeam.page';
import { AccountTeamEditMemberPage } from '@/pages/shared/account/pages/team/pages/edit-member/AccountTeamEditMember.page';
import { AccountTeamInvitePage } from '@/pages/shared/account/pages/team/pages/invite/AccountTeamInvite.page';
import { ComplianceFrameworksPage } from '@/pages/shared/compliance-frameworks/ComplianceFrameworks.page';
import { PlotsPage } from '@/pages/shared/plots/Plots.page';
import { PlotPage } from '@/pages/shared/projects/pages/project/pages/plot/Plot.page';
import { ProjectPage } from '@/pages/shared/projects/pages/project/Project.page';
import { ProjectPageLayout } from '@/pages/shared/projects/pages/project/ProjectLayout.page';
import { BiodiversityDetailPage } from '@/pages/shared/projects/pages/v1_2/project/pages/biodiversity/BiodiversityDetail.page';
import { CarbonDetailPage } from '@/pages/shared/projects/pages/v1_2/project/pages/carbon/CarbonDetail.page';
import { DeforestationDetailPage } from '@/pages/shared/projects/pages/v1_2/project/pages/deforestation/DeforestationDetail.page';
import { ImpactProjectionsPage } from '@/pages/shared/projects/pages/v1_2/project/pages/impact-projections/ImpactProjections.page';
import { InteractiveMap } from '@/pages/shared/projects/pages/v1_2/project/pages/interactive-map/InteractiveMap.page';
import { LandMonitoringPage } from '@/pages/shared/projects/pages/v1_2/project/pages/land-monitoring/LandMonitoring.page';
import { NCAPage } from '@/pages/shared/projects/pages/v1_2/project/pages/nca/Nca.page';
import { PlotPage as V1_2_PlotPage } from '@/pages/shared/projects/pages/v1_2/project/pages/plot/Plot.page';
import { PlotsPage as V1_2PlotsPage } from '@/pages/shared/projects/pages/v1_2/project/pages/plots/Plots.page';
import { WaterDetailPage } from '@/pages/shared/projects/pages/v1_2/project/pages/water/WaterDetail.page';
import { ProjectPage as V1_2ProjectPage } from '@/pages/shared/projects/pages/v1_2/project/Project.page';
import { ProjectsPage } from '@/pages/shared/projects/Projects.page';
import { paths } from '@/routing';
import { RequireAdminRole } from '@/routing/components/protections/require-admin-role/RequireAdminRole';
import { SkipLoggedInUser } from '@/routing/components/protections/skip-logged-in-user/SkipLoggedInUser';

import { BuyerAccountPageWrapper } from '../pages/account/account.page-wrapper';
import { BuyerInvestmentsPage } from '../pages/investments/Investments.page';
import { BuyerPortfolioPage } from '../pages/portfolio/Portfolio.page';
import { BuyerComplianceFrameworksPageWrapper } from '../pages/projects/pages/compliance-frameworks/ComplianceFrameworks.page-wrapper';
import { BuyerSignedInRoute } from './components/protections/buyer-signed-in-route/BuyerSignedInRoute';

export const BuyerPageRoutes = (
  <Route path='buyer'>
    <Route element={<BuyerSignedInRoute />}>
      <Route path={paths.buyer.account} element={<BuyerAccountPageWrapper />}>
        <Route index element={<AccountPage />} />
        <Route path={paths.buyer.accountEditEmail} element={<AccountEditEmailPage />} />
        <Route path={paths.buyer.accountEditName} element={<AccountEditNamePage />} />
        <Route path={paths.buyer.accountEditPassword} element={<AccountEditPasswordPage />} />
        <Route path={paths.buyer.accountLegal} element={<AccountLegalPage />} />
        <Route path={paths.buyer.accountTeam}>
          <Route index element={<AccountTeamPage />} />
          <Route element={<RequireAdminRole />}>
            <Route path={paths.buyer.accountTeamEditMember} element={<AccountTeamEditMemberPage />} />
            <Route path={paths.buyer.accountTeamInvite} element={<AccountTeamInvitePage />} />
          </Route>
        </Route>
      </Route>

      <Route path={paths.buyer.portfolio} element={<BuyerPortfolioPage />} />
      <Route index element={<Navigate to={paths.buyer.projects} />} />
      <Route path={paths.buyer.investments} element={<BuyerInvestmentsPage />} />

      <Route path={paths.buyer.projects}>
        <Route index element={<ProjectsPage />} />
        <Route path={paths.buyer.projectsComplianceFrameworks} element={<BuyerComplianceFrameworksPageWrapper />}>
          <Route index element={<ComplianceFrameworksPage />} />
        </Route>
        <Route element={<V1_2ProjectPage />}>
          <Route path={paths.buyer.v1_2_landMonitoring} element={<LandMonitoringPage />} />
          <Route path={paths.buyer.v1_2_nca} element={<NCAPage />} />
          <Route path={paths.buyer.v1_2_plots} element={<V1_2PlotsPage />} />
          <Route path={paths.buyer.v1_2_impactProjections} element={<ImpactProjectionsPage />} />
        </Route>
        <Route path={paths.buyer.v1_2_interactiveMap} element={<InteractiveMap />} />
        <Route path={paths.buyer.v1_2_projectDetailsBiodiversity} element={<BiodiversityDetailPage />} />
        <Route path={paths.buyer.v1_2_projectDetailsCarbon} element={<CarbonDetailPage />} />
        <Route path={paths.buyer.v1_2_projectDetailsWater} element={<WaterDetailPage />} />
        <Route path={paths.buyer.v1_2_projectDetailsDeforestation} element={<DeforestationDetailPage />} />

        <Route element={<ProjectPageLayout />}>
          <Route path={paths.buyer.projectDetails} element={<ProjectPage />} />
          <Route path={paths.buyer.plots} element={<PlotsPage />} />
        </Route>
      </Route>
      {/* TODO: MVP-3284: Rename to PlotPage */}
      <Route path={paths.buyer.v1_2_plot} element={<V1_2_PlotPage />} />
      <Route path={paths.buyer.plot} element={<PlotPage />} />
    </Route>

    <Route
      element={
        <SkipLoggedInUser>
          <Outlet />
        </SkipLoggedInUser>
      }
    >
      <Route path={paths.buyer.completeAccount} element={<BuyerCompleteAccountPage />} />
    </Route>
  </Route>
);
