import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { BiodiversityZoneChart, BiodiversityZoneChartData } from '@/components/ChartsV1_2';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';

import { NoPlotData } from '../NoPlotData';

export const BiodiversityGraphTile = () => {
  const { t } = useTranslation();

  const plot = usePlot().data;

  const { getFact } = usePlotReportForPlot({ plotId: plot.id });

  const biodiversityZonePercentHistoricGraph = getFact<BiodiversityZoneChartData[]>(
    R1FactType.r1_biodiversity_zone_percent_historic_graph,
  )?.value;

  /* variable == null checks for both null and undefined */
  const noChartData = biodiversityZonePercentHistoricGraph == null;

  if (noChartData) {
    return <NoPlotData />;
  }

  return (
    <Stack
      spacing={6}
      className='items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'
      data-testid='biodiversity-graph-tile'
    >
      <ChartHeading
        heading={t('shared.ncaDetail.details.biodiversity.labels.historicalGraphHeadline')}
        popover={{
          title: t('shared.ncaDetail.details.biodiversity.historicalData.title'),
          body: t('shared.ncaDetail.details.biodiversity.historicalData.body'),
        }}
      />
      <BiodiversityZoneChart
        data={biodiversityZonePercentHistoricGraph}
        height={233}
        showTooltip={true}
        className='relative'
        data-testid='biodiversity-zone-chart'
      />
    </Stack>
  );
};
