import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import {
  FactsByFactType,
  GET_BUYER_DASHBOARD_PLOT_REPORT_PATH,
  GET_DASHBOARD_PLOT_REPORT_PATH,
  GetBuyerDashboardPlotReportErrorDto,
  GetBuyerDashboardPlotReportSuccessDto,
  GetDashboardPlotReportErrorDto,
  GetDashboardPlotReportSuccessDto,
} from '@/api/rest/resources/types/dashboard';
import { Fact, R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useMembershipType } from '@/hooks/useMembershipType';

type SuccessDto = GetDashboardPlotReportSuccessDto | GetBuyerDashboardPlotReportSuccessDto;
type ErrorDto = GetDashboardPlotReportErrorDto | GetBuyerDashboardPlotReportErrorDto;

export type ReportByPlot = Record<string, FactsByFactType>;

export const getPlotReportForPlot = async ({
  plotId,
  membershipType,
}: {
  plotId: string;
  membershipType: MembershipWithOrganizationTypeEnum;
}) => {
  const factsReducer = (acc: FactsByFactType, curr: Fact) => ({ ...acc, [curr.name]: curr });
  const response = await getRequest<SuccessDto>(
    membershipType === MembershipWithOrganizationTypeEnum.land_steward
      ? GET_DASHBOARD_PLOT_REPORT_PATH
      : GET_BUYER_DASHBOARD_PLOT_REPORT_PATH,
    { plot_id: plotId },
    undefined,
  );

  return (response.facts ?? []).reduce(factsReducer, {} as FactsByFactType);
};

export const usePlotReportForPlot = ({ plotId }: { plotId: string }) => {
  const membershipType = useMembershipType();
  const query = useSuspenseQuery<FactsByFactType, ErrorDto>({
    queryKey: [membershipType, 'dashboard', 'plot-report', plotId],
    queryFn: () => getPlotReportForPlot({ plotId, membershipType }),
    staleTime: 1000 * 60 * 5,
  });

  return {
    ...query,

    getFact: <F = unknown>(factType: R1FactType) => {
      return (query.data[`${factType}`] as Fact<F>) ?? ({ name: factType, value: null } as Fact<F>);
    },
  };
};
