import { Divider, Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import {
  getNCChangeIcon,
  getNCChangeIconVariant,
  NCCardSectionHeader,
  NCFactCapsule,
  printLatestDate,
  useNCCardContext,
} from '@/components';
import { useScreenSize } from '@/hooks/useScreenSize';
import { usePlotId } from '@/pages/shared/hooks/usePlotId';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';
import { formatKilogramsToTonnes } from '@/utils/formatting';

export const CarbonStatsTile = () => {
  const isSmallScreen = useScreenSize() === 'small';

  return (
    <Stack className='gap-8 py-8 lg:gap-4' data-testid='carbon-stats-tile'>
      <BelowGroundCarbonStorage />

      {!isSmallScreen && <Divider className='border-dashed' />}

      <GHGEmissions />

      {!isSmallScreen && <Divider className='border-dashed' />}

      <UpliftPotential />
    </Stack>
  );
};

const BelowGroundCarbonStorage = () => {
  const { t } = useTranslation();
  const plotId = usePlotId();
  const { analysisType } = useNCCardContext();

  const { getFact } = usePlotReportForPlot({ plotId });

  const carbonBgLatestAnalysis = formatKilogramsToTonnes(
    getFact<number>(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_latest_analysis_total
        : R1FactType.r1_carbon_bg_latest_analysis_per_ha,
    ),
  );

  const carbonBgSincePreviousAnalysis = formatKilogramsToTonnes(
    getFact<number>(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_previous_1y_uplift_total
        : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha,
    ),
  );

  const lastMeasuredDate = printLatestDate([carbonBgLatestAnalysis?.measured_at]);
  const carbonBgSincePreviousAnalysisInitialMeasuredAt = printLatestDate([carbonBgSincePreviousAnalysis?.measured_at]);
  const carbonBgSincePreviousAnalysisPreviousMeasuredAt = printLatestDate([
    carbonBgSincePreviousAnalysis?.previous_1y_measured_at,
  ]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.carbon.labels.belowTheGroundCarbonStorage')}
        supplementaryInfo={`${t('shared.ncaDetail.details.labels.latestAnalysis')} ${lastMeasuredDate}`}
        popover={{
          title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.latestAnalysis')}
          fact={carbonBgLatestAnalysis}
          capsuleIconProps={{
            icon: getNCChangeIcon(carbonBgLatestAnalysis),
            variant: getNCChangeIconVariant(carbonBgLatestAnalysis),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
            body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', { date: lastMeasuredDate }),
          }}
        />
        {!!carbonBgSincePreviousAnalysis?.value && (
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.sincePreviousAnalysis')}
            fact={carbonBgSincePreviousAnalysis}
            capsuleIconProps={{
              icon: getNCChangeIcon(carbonBgSincePreviousAnalysis),
              variant: getNCChangeIconVariant(carbonBgSincePreviousAnalysis),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.carbon.explainers.sincePreviousAnalysis.title'),
              body: t('shared.ncaDetail.details.carbon.explainers.sincePreviousAnalysis.body', {
                date: carbonBgSincePreviousAnalysisInitialMeasuredAt,
                previousDate: carbonBgSincePreviousAnalysisPreviousMeasuredAt,
              }),
              popoverTriggerProps: {
                'data-testid': 'whc-since-previous-analysis-tooltip',
              },
            }}
          />
        )}
      </Stack>
    </div>
  );
};

const GHGEmissions = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();
  const plotId = usePlotId();

  const { getFact } = usePlotReportForPlot({ plotId });

  const ghgEmissionsPerYearLatestEstimates = formatKilogramsToTonnes(
    getFact(
      analysisType === 'total'
        ? R1FactType.r1_ghg_emissions_per_year_latest_analysis_total
        : R1FactType.r1_ghg_emissions_per_year_latest_analysis_per_ha,
    ),
  );

  const lastMeasuredDate = printLatestDate([ghgEmissionsPerYearLatestEstimates?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.carbon.labels.ghgEmissionsPerYear')}
        supplementaryInfo={`${t('shared.ncaDetail.details.labels.latestEstimates')} ${lastMeasuredDate}`}
        popover={{
          title: t('shared.ncaDetail.details.carbon.explainers.ghgEmissionsPerYear.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.ghgEmissionsPerYear.body'),
        }}
      />
      <NCFactCapsule
        label={t('shared.ncaDetail.details.labels.latestEstimates')}
        fact={ghgEmissionsPerYearLatestEstimates}
        capsuleIconProps={{
          icon: getNCChangeIcon(ghgEmissionsPerYearLatestEstimates),
          variant: getNCChangeIconVariant(ghgEmissionsPerYearLatestEstimates),
        }}
        infoPopoverProps={{
          title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
          body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
            date: lastMeasuredDate,
          }),
        }}
      />
    </div>
  );
};

const UpliftPotential = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();
  const plotId = usePlotId();

  const { getFact } = usePlotReportForPlot({ plotId });

  const carbonBg1YearUpliftPotential = formatKilogramsToTonnes(
    getFact<number>(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_1year_uplift_potential_total
        : R1FactType.r1_carbon_bg_1year_uplift_potential_per_ha,
    ),
  );

  const carbonBg10YearUpliftPotential = formatKilogramsToTonnes(
    getFact<number>(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_10year_uplift_potential_total
        : R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha,
    ),
  );

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.carbon.labels.upliftPotentialForCarbonStorage')}
        popover={{
          title: t('shared.ncaDetail.details.carbon.explainers.upliftPotentialForBelowTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.upliftPotentialForBelowTheGroundCarbonStorage.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.1YearPotential')}
          fact={carbonBg1YearUpliftPotential}
          capsuleIconProps={{
            icon: getNCChangeIcon(carbonBg1YearUpliftPotential),
            variant: getNCChangeIconVariant(carbonBg1YearUpliftPotential),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.carbon.explainers.1YearPotential.title'),
            body: t('shared.ncaDetail.details.carbon.explainers.1YearPotential.body'),
          }}
        />
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.10YearPotential')}
          fact={carbonBg10YearUpliftPotential}
          capsuleIconProps={{
            icon: getNCChangeIcon(carbonBg10YearUpliftPotential),
            variant: getNCChangeIconVariant(carbonBg10YearUpliftPotential),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.carbon.explainers.10YearPotential.title'),
            body: t('shared.ncaDetail.details.carbon.explainers.10YearPotential.body'),
          }}
        />
      </Stack>
    </div>
  );
};
