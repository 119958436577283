/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { cn, theme } from '@landler/tw-component-library';
import { ComponentProps, FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, ComposedChart, Label, Line, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { colorPalette } from '@/theme/colorPalette';
import { formatUnit } from '@/utils/formatting';
import { printYear } from '@/utils/formatting/date';

import { NCCardAnalysisType } from '../NCCard/NCCard';
import { ChartXTick, ChartYTick } from './components/Axis';
import { ChartLabel } from './components/ChartLabel';
import { ChartLegendBar, ChartLegendBarItem } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { NoChartData } from './NoChartData';
import { DefaultChartProps } from './types';
import { getXAxisDomainForPotentialGraph, minMaxAxisDomain } from './utils';

const chartColors = {
  wp: { stroke: '#0000004D', label: '#00000099' },
  whc: { stroke: '#3EC1DC', gradient: '#85DAFF' },
} as const;
const axisStrokeColor = (theme.colors as Record<string, string> | undefined)?.['neutral-black-12'];

export type WaterHoldingCapacityChartData = {
  date: string;
  name:
    | 'water_holding_capacity_potential'
    | 'water_holding_capacity_potential_per_ha'
    | 'below_wilting_point_potential'
    | 'below_wilting_point_potential_per_ha';
  value: number;
  unit: 'm^3' | 'm^3/ha';
};

type WaterHoldingCapacityChartProps = DefaultChartProps & {
  /** The chart data */
  data: WaterHoldingCapacityChartData[];
  analysisType: NCCardAnalysisType;
};

export const WaterHoldingCapacityChart: FC<HTMLAttributes<HTMLDivElement> & WaterHoldingCapacityChartProps> = ({
  analysisType,
  data,
  height = 400,
  width = '100%',
  showTooltip = true,
  className,
  children,
  ...delegated
}) => {
  const { t } = useTranslation();

  const styles = cn('flex w-full flex-col', className);
  const unit = analysisType === 'total' ? formatUnit(UnitEnum['m^3']) : formatUnit(UnitEnum['m^3/ha']);

  const dateDataMap = data.reduce((acc, curr) => {
    const { date, name, value } = curr;

    const whcValue = ['water_holding_capacity_potential', 'water_holding_capacity_potential_per_ha'].includes(name)
      ? value
      : acc[date]?.whc;

    const wpValue = ['below_wilting_point_potential', 'below_wilting_point_potential_per_ha'].includes(name)
      ? value
      : acc[date]?.wp;

    acc[date] = { whc: whcValue ?? null, wp: wpValue ?? null };

    return acc;
  }, {} as Record<string, { whc: number | null; wp: number | null }>);

  const chartData = Object.entries(dateDataMap)
    // @ts-ignore typescript does not allow arithmetic operations between date objects
    .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
    .map(([date, currentData]) => ({
      label: new Date(date).getFullYear(),
      ...currentData,
    }));

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  /**
   * Science/Design spec -
   * Reference lines should extend the whole range of the graph on both ends (min to max).
   * In cases where the timeseries data for reference lines might have gaps, or do not extend the whole min-max range -
   *    with connectNulls - recharts interpolates over null values and extends the line internally to fill in the gaps in these lines.
   *    For extrapolation, such that the line gets extended externally to the start and ends of the parent graph
   *    we manually fill in the min and max point for reference lines so that interpolation can take over.
   *    We pick the first non-null value as the min, and the last non-null value as the max.
   */
  const firstWpValue = chartData.find((point) => point.wp !== null)?.wp ?? null;
  const lastWpValue = chartData.findLast((point) => point.wp !== null)?.wp ?? null;

  chartData[0] = {
    ...chartData.at(0)!,
    wp: chartData.at(0)!.wp ?? firstWpValue,
  };
  chartData[chartData.length - 1] = {
    ...chartData.at(-1)!,
    wp: chartData.at(-1)?.wp ?? lastWpValue,
  };

  const WiltingPointChartLabel = (props: ComponentProps<typeof ChartLabel>) => {
    return (
      <ChartLabel
        {...props}
        dx={-2}
        dy={-8}
        stroke={chartColors.wp.label}
        className='typography-caption'
        value={t('global.analysis.wiltingPoint')}
      />
    );
  };

  const xTicks = getXAxisDomainForPotentialGraph(firstTick.label, lastTick.label);

  return (
    <div className={styles} {...delegated}>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id='waterHoldingCapacity' x1='0' y1='-0.86' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.whc.gradient} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.whc.gradient} stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='whc'
            stroke={chartColors.whc.stroke}
            strokeWidth={3}
            fill='url(#waterHoldingCapacity)'
            fillOpacity={1}
            activeDot={(props) => ChartTooltipDot(props)}
            connectNulls
          />
          <Line
            type='stepAfter'
            dataKey='wp'
            connectNulls
            activeDot={false}
            dot={false}
            strokeWidth={1}
            isAnimationActive={false}
            stroke={chartColors.wp.stroke}
            strokeDasharray='4 4'
            label={(props) =>
              /**
               * Recharts API does not offer a method to add a tag/label to the whole chart
               * Hence, we use the label component as a hack to add a label to the whole chart
               * by rendering a custom label only on the last datapoint
               */
              props.index === chartData.length - 1 ? <WiltingPointChartLabel {...props} /> : <></>
            }
          />
          <XAxis
            dataKey='label'
            tickLine={false}
            tick={<ChartXTick />}
            padding={{ right: 25 }}
            type='number'
            domain={['dataMin', 'dataMax']}
            ticks={xTicks}
            stroke={axisStrokeColor}
          />
          <YAxis
            tickLine={false}
            type='number'
            scale='sequential'
            domain={minMaxAxisDomain(0.97, 1.03)}
            tick={<ChartYTick />}
            padding={{ top: 25 }}
            width={65}
            stroke={axisStrokeColor}
          >
            <Label className='typography-caption text-text-secondary' value={unit} position={{ x: 57, y: 10 }} />
          </YAxis>
          {showTooltip && (
            <Tooltip
              cursor={{ stroke: colorPalette.divider }}
              position={{ y: 0 }}
              allowEscapeViewBox={{ x: true }}
              animationDuration={150}
              content={(content) => <CustomTooltip {...content} unit={unit as UnitEnum} />}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
      <CustomLegendBar />
      {children}
    </div>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.waterHoldingCapacity'),
      color: chartColors.whc.gradient,
    },
    {
      label: t('global.misc.references'),
      color: chartColors.wp.stroke,
      shape: <span className='w-3 border border-dashed' style={{ borderColor: chartColors.wp.stroke }} />,
    },
  ];

  return <ChartLegendBar items={legendBarItems} data-testid='water-holding-capacity-chart-legend' />;
};

const CustomTooltip = ({ active, payload, label, unit }: TooltipProps<ValueType, NameType> & { unit: UnitEnum }) => {
  const { t } = useTranslation();
  const whcValue = payload?.find((data) => data.dataKey === 'whc');
  const whcDisplay = whcValue?.value
    ? `${getDisplayNumber(whcValue?.value as string, window.navigator.language)} ${unit}`
    : t('global.analysis.noData');

  if (!active) return null;

  return (
    <ChartTooltip>
      <ChartTooltipTextPrimary>{`${t('global.analysis.waterHoldingCapacity')}: ${whcDisplay}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextSecondary>{printYear(`${label}`)}</ChartTooltipTextSecondary>
    </ChartTooltip>
  );
};
