import { useProjectId } from '@/pages/shared/hooks/useProjectId';

import { ConservationProjectPage } from './components/conservation/ConservationProjectPage';
import { SustainableProductionProjectPage } from './components/sustainableProduction/SustainableProductionProjectPage';

export const ProjectPage = () => {
  const projectId = useProjectId();

  if (projectId === 'majete') {
    return <ConservationProjectPage />;
  }

  return <SustainableProductionProjectPage />;
};
