import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import bbox from '@turf/bbox';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import { LngLatBoundsLike } from 'mapbox-gl';

import { CellProperties, Project } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';

export const getPolygonByProjectId = async (projectId: Project['id']) => {
  const module = await import(`../mockData/${projectId}/polygon.json`);
  return module.default as FeatureCollection;
};

export const useProjectBoundary = () => {
  const membershipType = useMembershipType();
  const projectId = useProjectId();

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectId, 'boundary'],
    queryFn: () => getPolygonByProjectId(projectId),
  });
};

export const useProjectBounds = () => {
  const membershipType = useMembershipType();
  const projectId = useProjectId();

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectId, 'boundary', 'bounds'],
    queryFn: () => getPolygonByProjectId(projectId).then((polygon) => bbox(polygon) as LngLatBoundsLike),
  });
};

export const getProjectCells = async ({ projectId, year }: { projectId: Project['id']; year: string }) => {
  const module = await import(`../mockData/${projectId}/cells_${year}.json`);
  return module.default as FeatureCollection<Geometry, CellProperties>;
};

export const useProjectCells = ({ year }: { year: string }) => {
  const membershipType = useMembershipType();
  const projectId = useProjectId();

  return useQuery({
    queryKey: [membershipType, 'project', 'conservation', projectId, 'cells', year],
    queryFn: async () => {
      const cells = await getProjectCells({ projectId, year });
      return {
        ...cells,
        features: cells.features.map((feature, index) => ({
          ...feature,
          id: index + 1,
        })),
      } as FeatureCollection<Geometry, CellProperties>;
    },
  });
};

export const useProjectCellsById = ({ year }: { year: string }) => {
  const membershipType = useMembershipType();
  const projectId = useProjectId();

  const cells = useProjectCells({ year });

  const cellsById = cells.data?.features.reduce((acc, curr) => {
    acc[curr.properties.id] = curr;
    return acc;
  }, {} as Record<string, Feature<Geometry, CellProperties>>);

  return useQuery({
    queryKey: [membershipType, 'project', 'conservation', projectId, 'cells', 'id', year],
    queryFn: () => cellsById,
  });
};
