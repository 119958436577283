import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { LocalStorage } from '@/lib/storage/LocalStorage';
import { ApplicationLanguage } from '@/types';

import translationDE from '../lang/de.json';
import translationEN from '../lang/en.json';
import translationES from '../lang/es.json';
import translationFR from '../lang/fr.json';

const languages = [
  { key: ApplicationLanguage.DE, translation: translationDE },
  { key: ApplicationLanguage.EN, translation: translationEN },
  { key: ApplicationLanguage.ES, translation: translationES },
  { key: ApplicationLanguage.FR, translation: translationFR },
];

export const resources = {
  de: { translation: translationDE },
  en: { translation: translationEN },
  es: { translation: translationES },
  fr: { translation: translationFR },
} as const;

export const fallbackLanguage = ApplicationLanguage.EN;

export const langIdentifier = 'lang';

i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: fallbackLanguage,
    supportedLngs: languages.map((lng) => lng.key),
    resources,
    detection: {
      order: ['querystring', 'localStorage', 'navigator', 'htmlTag'],
      caches: [],
      lookupQuerystring: langIdentifier,
      lookupLocalStorage: langIdentifier,
    },
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18next.on('languageChanged', () => {
  LocalStorage.setItem(langIdentifier, i18next.language);
});

// eslint-disable-next-line import/no-default-export
export default i18next;
