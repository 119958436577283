import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { BiodiversityZoneChart, BiodiversityZoneChartData } from '@/components/ChartsV1_2';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { useNCData } from '@/pages/shared/hooks/useNCData';

import { NoProjectData } from '../../../../components/NoProjectData';

export const BiodiversityCardGraphTile = () => {
  const { t } = useTranslation();
  const biodiversityZonePercentHistoricGraph = useNCData<BiodiversityZoneChartData[]>(
    R1FactType.r1_biodiversity_zone_percent_historic_graph,
  )?.value;

  const noChartData = !biodiversityZonePercentHistoricGraph?.length;

  if (noChartData) {
    return <NoProjectData />;
  }

  return (
    <Stack center data-testid='biodiversity-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'>
        <ChartHeading
          heading={t('shared.ncaDetail.details.biodiversity.labels.historicalGraphHeadline')}
          popover={{
            title: t('shared.ncaDetail.details.biodiversity.historicalData.title'),
            body: t('shared.ncaDetail.details.biodiversity.historicalData.body'),
          }}
        />
        <BiodiversityZoneChart
          data={biodiversityZonePercentHistoricGraph}
          height={233}
          showTooltip={true}
          className='relative'
          data-testid='biodiversity-zone-chart'
        />
      </Stack>
    </Stack>
  );
};
