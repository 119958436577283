import { Button, Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';

import { PROJECT_CELLS_SOURCE_ID } from '../constants';
import { useControlsContext } from '../hooks/useControlsForm';

export const InfoPanel = () => {
  const { t } = useTranslation();

  const controlsForm = useControlsContext();
  const cell = controlsForm.watch('cell');

  const mapRef = useMap();

  if (!cell) {
    return null;
  }

  const closePanel = () => {
    controlsForm.setValue('cell', undefined);

    const map = mapRef?.current;

    if (!map) {
      return;
    }

    map.setFeatureState({ source: PROJECT_CELLS_SOURCE_ID, id: cell }, { active: false });
  };

  return (
    <Stack className='h-full w-[413px] bg-white-100' data-testid='interactive-map-info-panel'>
      <Stack className='flex-1 overflow-auto'>{/* Content */}</Stack>
      <Stack direction='row' className='justify-end p-6 pb-10'>
        <Button variant='outline' onClick={closePanel}>
          {t('global.ui.buttons.close')}
        </Button>
      </Stack>
    </Stack>
  );
};
