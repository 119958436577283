import { useSuspenseQuery } from '@tanstack/react-query';

import { Project, Tileset } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';

type TilesetsByLayer = {
  [layer: string]: {
    [year: string]: Tileset;
  };
};

const getTilesetsByProjectId = async (projectId: Project['id']) => {
  const module = await import(`../mockData/${projectId}/tilesets.json`);

  return module.default as TilesetsByLayer;
};

export const useTilesetsByLayer = () => {
  const membershipType = useMembershipType();
  const projectId = useProjectId();

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', 'tilesets', projectId],
    queryFn: () => getTilesetsByProjectId(projectId),
  });
};
