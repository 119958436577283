import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { Plot, PlotStatusEnum } from '@/api/rest/resources/types/plot';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';

export const useDestinationPath = () => {
  const membershipType = useMembershipType();
  const project = useProjectDetailById().data;
  const isProjectEditable = getProjectPermissions(project).includes('write');

  const getDestinationPath = (plot: Plot) => {
    if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) {
      if (plot.status === PlotStatusEnum.invalid && !isProjectEditable) {
        return null;
      }
      if (plot.status === PlotStatusEnum.invalid) {
        return buildPath(paths.landSteward.editPlot, {
          pathParams: { projectId: project.id, plotId: plot.id },
        });
      }
      return buildPath(paths.landSteward.v1_2_plot, {
        pathParams: { projectId: project.id, plotId: plot.id },
      });
    }

    if (plot.status !== PlotStatusEnum.invalid) {
      return buildPath(paths.buyer.v1_2_plot, {
        pathParams: { projectId: project.id, plotId: plot.id },
      });
    }
    return null;
  };

  return { getDestinationPath };
};
