/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { cn, theme } from '@landler/tw-component-library';
import { ComponentProps, FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, ComposedChart, Label, Line, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { colorPalette } from '@/theme/colorPalette';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';

import { NCCardAnalysisType } from '../NCCardV1_2/NCCard';
import { ChartXTick, ChartYTick } from './components/Axis';
import { ChartLabel } from './components/ChartLabel';
import { ChartLegendBar, ChartLegendBarItem } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { NoChartData } from './NoChartData';
import { DefaultChartProps } from './types';
import { getXAxisDomainForHistoricGraph, minMaxAxisDomain } from './utils';

const chartColors = {
  wp: { stroke: '#0000004D', label: '#00000099' },
  whc: { stroke: '#43AFE2', gradient: '#6ACDE1' },
  sm: { stroke: '#34A4E2' },
} as const;

const axisStrokeColor = (theme.colors as Record<string, string> | undefined)?.['neutral-black-12'];

export type WaterHistoricalChartData = {
  date: string;
  name: 'soil_moisture' | 'water_holding_capacity' | 'below_wilting_point';
  value: number;
  unit: string;
};

type WaterHistoricalChartProps = DefaultChartProps & {
  /** The chart data */
  data: WaterHistoricalChartData[];
  analysisType: NCCardAnalysisType;
};

export const WaterHistoricalChart: FC<HTMLAttributes<HTMLDivElement> & WaterHistoricalChartProps> = ({
  analysisType,
  data,
  height = 400,
  width = '100%',
  showTooltip = true,
  className,
  children,
  ...delegated
}) => {
  const { t } = useTranslation();
  const styles = cn('flex w-full flex-col', className);
  const unit = analysisType === 'total' ? formatUnit(UnitEnum['m^3']) : formatUnit(UnitEnum['m^3/ha']);

  const whcYearlyValues: Record<string, number | null> = {};
  const wpYearlyValues: Record<string, number | null> = {};

  const dateDataMap = data.reduce((acc, curr) => {
    const { date, name, value } = curr;
    /** Since we receive time series that may not overlap on all timestamps (either sm/whc/wp may be undefined for the same date)
     *  in such cases, we default to null (not 0, since 0 is a valid value) for that particular date
     *  The area graph will then connect these null values along the other non-null values to create a continuous graph
     *  If these points are not handled, the graph breaks since we need valid Y values along the shared X axis
     */
    const smValue = ['soil_moisture', 'soil_moisture_per_ha'].includes(name) ? value : acc[date]?.sm;

    const whcValue = ['water_holding_capacity', 'water_holding_capacity_per_ha'].includes(name)
      ? value
      : acc[date]?.whc;
    whcYearlyValues[new Date(date).getFullYear().toString()] = whcValue ?? null;

    const wpValue = ['below_wilting_point', 'below_wilting_point_per_ha'].includes(name) ? value : acc[date]?.wp;
    wpYearlyValues[new Date(date).getFullYear().toString()] = wpValue ?? null;

    acc[date] = { sm: smValue ?? null, whc: whcValue ?? null, wp: wpValue ?? null };

    return acc;
  }, {} as Record<string, { sm: number | null; whc: number | null; wp: number | null }>);

  Object.keys(dateDataMap).forEach((date) => {
    /**
     * Science spec -
     * whc is produced as 1 value per year, constant throughout the year, sm can have multiple values in a year
     * we use this whc value to fill in whc for all sm datapoints of that year that do not have a coninciding whc datapoint
     * so that the tooltip does not show "No data" for whc since whc is null for those dates
     * but as shown in the graph the step chart for whc would indicate a corresponding whc value
     */
    const defaultWhcValue = dateDataMap[date]?.whc ?? whcYearlyValues[new Date(date).getFullYear().toString()];
    const defaultWpValue = dateDataMap[date]?.wp ?? wpYearlyValues[new Date(date).getFullYear().toString()];

    dateDataMap[date] = { sm: dateDataMap[date]?.sm ?? null, whc: defaultWhcValue ?? null, wp: defaultWpValue ?? null };
  });

  const chartData = Object.entries(dateDataMap)
    // @ts-ignore typescript does not allow arithmetic operations between date objects
    .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
    .map(([date, currentData]) => ({
      label: new Date(date).getTime(),
      ...currentData,
    }));

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  /**
   * Science/Design spec -
   * Reference lines should extend the whole range of the graph on both ends (min to max).
   * In cases where the timeseries data for reference lines might have gaps, or do not extend the whole min-max range -
   *    with connectNulls - recharts interpolates over null values and extends the line internally to fill in the gaps in these lines.
   *    For extrapolation, such that the line gets extended externally to the start and ends of the parent graph
   *    we manually fill in the min and max point for reference lines so that interpolation can take over.
   *    We pick the first non-null value as the min, and the last non-null value as the max.
   */
  const firstWpValue = chartData.find((point) => point.wp !== null)?.wp ?? null;
  const lastWpValue = chartData.findLast((point) => point.wp !== null)?.wp ?? null;

  chartData[0] = {
    ...chartData.at(0)!,
    wp: chartData.at(0)!.wp ?? firstWpValue,
  };
  chartData[chartData.length - 1] = {
    ...chartData.at(-1)!,
    wp: chartData.at(-1)?.wp ?? lastWpValue,
  };

  const WiltingPointChartLabel = (props: ComponentProps<typeof ChartLabel>) => {
    return (
      <ChartLabel
        {...props}
        dx={-2}
        dy={-8}
        stroke={chartColors.wp.label}
        className='typography-caption'
        value={t('global.analysis.wiltingPoint')}
      />
    );
  };

  const xTicks = getXAxisDomainForHistoricGraph(firstTick.label, lastTick.label);

  return (
    <div className={styles} {...delegated}>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id='waterHoldingCapacity' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.whc.gradient} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.whc.gradient} stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='sm'
            stroke={chartColors.sm.stroke}
            strokeWidth={1}
            fill='none'
            activeDot={(props) => ChartTooltipDot(props)}
            connectNulls
          />
          <Area
            isAnimationActive={false}
            type='stepAfter'
            dataKey='whc'
            stroke={chartColors.whc.stroke}
            strokeWidth={2}
            fill='url(#waterHoldingCapacity)'
            activeDot={(props) => ChartTooltipDot(props)}
            connectNulls
          />
          <Line
            type='stepAfter'
            dataKey='wp'
            connectNulls
            activeDot={false}
            isAnimationActive={false}
            stroke={chartColors.wp.stroke}
            strokeDasharray='4 4'
            dot={false}
            strokeWidth={1}
            label={(props) =>
              /**
               * Recharts API does not offer a method to add a tag/label to the whole chart
               * Hence, we use the label component as a hack to add a label to the whole chart
               * by rendering a custom label only on the last datapoint
               */
              props.index === chartData.length - 1 ? <WiltingPointChartLabel {...props} /> : <></>
            }
          />
          <XAxis
            dataKey='label'
            tickLine={false}
            tick={<ChartXTick formatter={printMonthYear} />}
            padding={{ right: 25 }}
            scale='time'
            type='number'
            tickFormatter={printMonthYear}
            domain={['dataMin', 'dataMax']}
            ticks={xTicks}
            stroke={axisStrokeColor}
          />
          <YAxis
            tickLine={false}
            type='number'
            scale='sequential'
            domain={minMaxAxisDomain(0, 1.2)}
            tick={<ChartYTick />}
            padding={{ top: 25 }}
            stroke={axisStrokeColor}
            width={65}
          >
            <Label className='typography-caption text-text-secondary' value={unit} position={{ x: 57, y: 10 }} />
          </YAxis>
          {showTooltip && (
            <Tooltip
              cursor={{ stroke: colorPalette.divider }}
              position={{ y: 0 }}
              allowEscapeViewBox={{ x: true }}
              animationDuration={150}
              content={(content) => <CustomTooltip {...content} unit={unit as UnitEnum} />}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
      <CustomLegendBar />
      {children}
    </div>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.waterHoldingCapacity'),
      color: chartColors.whc.gradient,
    },
    {
      label: t('global.analysis.soilMoisture'),
      color: chartColors.sm.stroke,
    },
    {
      label: t('global.misc.references'),
      color: chartColors.wp.stroke,
      shape: <span className='w-3 border border-dashed' style={{ borderColor: chartColors.wp.stroke }} />,
    },
  ];

  return (
    <ChartLegendBar items={legendBarItems} className='mt-8 sm:ml-[28px]' data-testid='water-historical-chart-legend' />
  );
};

const CustomTooltip = ({ active, payload, label, unit }: TooltipProps<ValueType, NameType> & { unit: UnitEnum }) => {
  const { t } = useTranslation();

  /** sm/whc values are not stored in any particular order in the payload array when multiple graphs are rendered,
   *  the payload just carries non-null values, null values are omitted.
   *  If either whc/sm are null, they will not show up in the payload array
   *  Hence we search for the object for a dataKey match, instead of indexing the array
   *  if they exist, we extract the y value, if not, we assume its null
   */
  const smValue = payload?.find((data) => data.dataKey === 'sm');
  const whcValue = payload?.find((data) => data.dataKey === 'whc');
  const smDisplay = smValue?.value
    ? `${getDisplayNumber(smValue?.value as string, window.navigator.language)} ${unit}`
    : t('global.analysis.noData');
  const whcDisplay = whcValue?.value
    ? `${getDisplayNumber(whcValue?.value as string, window.navigator.language)} ${unit}`
    : t('global.analysis.noData');

  if (!active) return null;

  return (
    <ChartTooltip>
      <ChartTooltipTextPrimary>{`${t('global.analysis.soilMoisture')}: ${smDisplay}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextPrimary>{`${t('global.analysis.waterHoldingCapacity')}: ${whcDisplay}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextSecondary>{`${t('global.misc.measuredAt')}: ${printMonthYear(
        label,
      )}`}</ChartTooltipTextSecondary>
    </ChartTooltip>
  );
};
