import { Fact, R1FactType, SIGNED_FACT_TYPES } from '@/api/rest/resources/types/fact';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { formatUnit } from '@/utils/formatting';

import { getDisplayNumber } from './useDisplayNumber';

type UseFactResult = {
  display: string;
  value?: string | number;
  unit?: string;
};

const prettifyDisplayedValue = (value: string, unit?: string) => {
  if (!unit || unit === '') {
    return value;
  }

  return `${value} ${unit}`;
};

export const useFact = (fact?: Fact): UseFactResult => {
  if (!fact || !isValidFactValue(fact.value) || !fact.unit) {
    return {
      display: 'N/A',
    };
  }

  const { name, value, unit } = fact;

  const showSign = SIGNED_FACT_TYPES.includes(name as R1FactType);
  const formattedValue =
    typeof value === 'number'
      ? getDisplayNumber(value, window.navigator.language, { signDisplay: showSign ? 'exceptZero' : 'never' })
      : value;
  const formattedUnit = formatUnit(unit);

  return {
    display: prettifyDisplayedValue(formattedValue, formattedUnit),
    value,
    unit: unit === UnitEnum.none ? undefined : formattedUnit,
  };
};

const isValidFactValue = (value: unknown): value is number | string => {
  return ['string', 'number'].includes(typeof value);
};
