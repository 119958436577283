import React, { useMemo } from 'react';

import { RiInformationLine } from '../Icon/Icon';
import { cn } from '../utils';
import {
  Popover,
  PopoverContent,
  PopoverContentBody,
  PopoverContentProps,
  PopoverContentTitle,
  PopoverTrigger,
  PopoverTriggerProps,
} from './Popover';

export type InfoPopoverProps = Omit<PopoverContentProps, 'children' | 'title'> & {
  title?: React.ReactNode;
  body?: React.ReactNode;
  popoverTriggerProps?: PopoverTriggerProps & {
    /**
     * @default
     * <RiInformationLine />
     */
    icon?: React.ReactNode;
  };
};

export const InfoPopover = ({ popoverTriggerProps, title, body, ...delegatedContentProps }: InfoPopoverProps) => {
  const triggerComponent = useMemo(() => {
    const { icon, className, ...delegatedTriggerProps }: InfoPopoverProps['popoverTriggerProps'] = {
      icon: <RiInformationLine />,
      'data-testid': 'info-popover',
      'data-cy': 'info-popover',
      ...popoverTriggerProps,
    };

    return (
      <PopoverTrigger {...delegatedTriggerProps} className={cn('text-md', className)}>
        {icon}
      </PopoverTrigger>
    );
  }, [popoverTriggerProps]);

  return (
    <Popover>
      {triggerComponent}
      <PopoverContent {...delegatedContentProps}>
        {!!title && <PopoverContentTitle>{title}</PopoverContentTitle>}
        {!!body && <PopoverContentBody data-cy='info-popover-body'>{body}</PopoverContentBody>}
      </PopoverContent>
    </Popover>
  );
};
