import bbox from '@turf/bbox';
import { feature, Geometry } from '@turf/helpers';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import { useEffect, useMemo } from 'react';
import { MapRef } from 'react-map-gl';

type UseBoundingBoxOptions = {
  geometries: Geometry[];
  padding?: Partial<{
    top: number;
    bottom: number;
    left: number;
    right: number;
  }>;
  mapRef?: MapRef | null;
};

export const useBoundingBox = ({ geometries, padding, mapRef }: UseBoundingBoxOptions) => {
  const bounds = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const bounds = calculateBounds(geometries);
    if (areBoundsValid(bounds)) return bounds;

    const completeWorldMap: BBox2d = [-170, -60, 170, 60];
    return completeWorldMap;
  }, [geometries]);

  useEffect(() => {
    mapRef?.fitBounds(bounds, {
      padding: { top: 0, bottom: 0, left: 0, right: 0, ...padding },
      maxZoom: 16,
    });
  }, [bounds, padding, mapRef]);

  return { bounds };
};

export const calculateBounds = (geometries: Geometry[]) => {
  const features = geometries.map((g) => feature(g));

  return bbox({
    type: 'FeatureCollection',
    features,
  }) as BBox2d;
};

const areBoundsValid = ([minX, minY, maxX, maxY]: BBox2d) => {
  let areValidBounds = true;

  const isValidX = (x: number) => x <= 180 && x >= -180;
  const isValidY = (y: number) => y <= 90 && y >= -90;

  if (!isValidX(minX)) areValidBounds = false;
  if (!isValidY(minY)) areValidBounds = false;
  if (!isValidX(maxX)) areValidBounds = false;
  if (!isValidY(maxY)) areValidBounds = false;

  return areValidBounds;
};
