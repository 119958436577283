export const PROJECT_BOUNDARY_SOURCE_ID = 'project-boundary';
export const PROJECT_CELLS_SOURCE_ID = 'project-cells';

type Color = {
  binary?: string;
  index?: [string, string];
};

export const COLORS = {
  buildings: {
    binary: '#4B2880',
  },

  crops: {
    binary: '#BCA013',
  },

  treeLoss: {
    binary: '#A4BE20',
  },
} satisfies Record<string, Color>;
