import { cn, Container } from '@landler/tw-component-library';

import { SecondaryNav } from '@/components';
import { usePathSet } from '@/hooks/usePathSet';
import { SignedInPageLayout } from '@/layout/signed-in-page';
import { useMenuItems } from '@/pages/shared/hooks/useMenuItems';

import { ProjectMap } from './components/ProjectMap';
import { ProjectOverview } from './components/ProjectOverview';
import { useProject } from './hooks/useProject';

export const ConservationProjectPage = () => {
  const menuItems = useMenuItems();
  const { data: project } = useProject();
  const pathSet = usePathSet();

  return (
    <SignedInPageLayout items={menuItems} withCustomContentContainer>
      <SecondaryNav title={project.name} backPath={pathSet.projects} />
      <Container gutterWidth={{ sm: 4 }} className='my-10' data-testid='conservation-project-page'>
        <div className={cn('grid grid-rows-[auto] gap-10', 'md:w-auto md:grid-cols-2')}>
          <ProjectMap />
          <ProjectOverview />
        </div>
      </Container>
    </SignedInPageLayout>
  );
};
