// TODO: MVP-3284 Rename folder to NCAExplainers and delete original

import {
  BottomSheet,
  BottomSheetContent,
  BottomSheetHeader,
  BottomSheetTitle,
  BottomSheetTitleProps,
  BottomSheetTrigger,
  BottomSheetTriggerProps,
  Button,
  cn,
  RiInformationLine,
  Stack,
  StackProps,
} from '@landler/tw-component-library';
import { FC, ReactNode } from 'react';

import { useScreenSize } from '@/hooks/useScreenSize';

export const Explainer = BottomSheet;

export const ExplainerTrigger: FC<BottomSheetTriggerProps & { children: string }> = ({ children, ...delegated }) => {
  const isSmallScreen = useScreenSize() === 'small';
  return (
    <BottomSheetTrigger asChild {...delegated}>
      <Button
        variant={isSmallScreen ? 'outline' : 'text'}
        rightAdornment={isSmallScreen ? null : <RiInformationLine />}
      >
        {children}
      </Button>
    </BottomSheetTrigger>
  );
};

export const ExplainerContent = BottomSheetContent;

export const ExplainerTitle: FC<BottomSheetTitleProps> = ({ children, ...delegated }) => {
  return (
    <BottomSheetHeader>
      <BottomSheetTitle {...delegated}>{children}</BottomSheetTitle>
    </BottomSheetHeader>
  );
};

export const ExplainerBody: FC<StackProps> = ({ children, className, ...delegated }) => {
  return (
    <Stack
      direction='row'
      spacing={10}
      className={cn('grid grid-flow-row auto-rows-auto pb-20 pt-10 md:auto-cols-auto md:grid-flow-col', className)}
      {...delegated}
    >
      {children}
    </Stack>
  );
};

export const ExplainerCopy: FC<{ title?: ReactNode; body?: ReactNode }> = ({ title, body }) => {
  return (
    <Stack>
      {title && <h3 className='typography-h3 mb-3 md:mb-6'>{title}</h3>}
      {typeof body === 'string'
        ? body.split('\n').map((string, index) => (
            <p key={index} className='mb-3 text-text-secondary last:mb-0'>
              {string}
            </p>
          ))
        : body}
    </Stack>
  );
};
